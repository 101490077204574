import React, { useEffect, useState } from "react";
import Loading from "../ui/Loading";
import { getQueueDetail } from "../../actions/logs";

// queue id show in modal component
const QueIdSearchModal = ({ QueueID, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);

  // fetch data according to the queue id
  const fetchQueueData = async () => {
    try {
      setIsLoading(true);
      const { response } = await dispatch(getQueueDetail(QueueID));
      if (response) {
        setResponseData(Object.entries(response));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // function call on page render
    fetchQueueData();
  }, [QueueID]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1>Queue details</h1>
          <div>
            <table>
              {responseData.map((row) => {
                return (
                  <tr>
                    {row.map((item, index) => {
                      return index === 0 ? <th>{item}</th> : <td>{item}</td>;
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default QueIdSearchModal;
