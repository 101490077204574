import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useFetch from '../../hooks/useFetch';
import Loading from '../../../components/ui/Loading';

export default function CompanyStatus() {
    const [fetchCompany, companyLoading] = useFetch();
    const [companyData, setCompanyData] = useState([]);
    const handleFetchCompany = async () => {
        try {
            const payload = {
                endPoint: "/api/all/companies",
                method: "get",
            };
            const res = await fetchCompany(payload);
            setCompanyData(res);
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        handleFetchCompany()
    }, [])

    const ActiveCompany = Array.isArray(companyData) && companyData.filter((item) => item.status === "Active")
    return (
        <>
            {companyLoading ? (
                <Loading />
            ) : (
                <div className="cstm-height-main-wrapper" style={{ marginTop: '30px' }}>
                    <div className="cstm-row mt-2 total-card">
                        <div className="cstm-col-3 companyInfoCardCol">
                            <div className="cstm-card">
                                <div className="cstm-row">
                                    <div className="cstm-col-6">
                                        <div className='cstmflexCenter'>
                                            <span className='cstm-card-svg'>
                                                <img
                                                    src="/images/logo/totalComanyIcon.svg"
                                                    alt="totalComanyIcon" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="cstm-col-6">
                                        <h2 className='cstm-card-head'>{companyData && companyData.length}</h2>
                                        <span className='cstm-sub-heading'>Total Companies</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cstm-col-3 companyInfoCardCol">
                            <div className="cstm-card">
                                <div className="cstm-row">
                                    <div className="cstm-col-6">
                                        <div className='cstmflexCenter'>
                                            <span className='cstm-card-svg'>
                                                <img
                                                    src="/images/logo/twobuilding.svg"
                                                    alt="twobuilding" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="cstm-col-6">
                                        <h2 className='cstm-card-head'>{ActiveCompany && ActiveCompany.length}</h2>
                                        <span className='cstm-sub-heading'>Active Companies</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cstm-row mt-2   ">
                        <div className="cstm-col-12">
                            <div className="cstm-card">
                                <div className="cstm-tbl_container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Company ShortName</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(companyData) &&
                                                companyData.slice(0, 10)
                                                    .map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td >{item.name}</td>
                                                                <td> {item.short_name}</td>
                                                                <td>{item.status}</td>
                                                            </tr>
                                                        )
                                                    })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='cstm-flex-end'>
                                    <Link to='/app/list-company' >
                                        <button className='ViewAllTable mt-2 '>View All...</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
