import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const CustomProductModal = (props) => {
  // const handleClose = () => {
  //   props.closeModal && props.closeModal();
  // };

  const handleModalClose = () => {
    if (props.history) {
      props.history.location.pathname.includes("ess")
        ? props.history.push("/ess")
        : props.history.location.pathname.includes("/app/list-company")
        ? props.closeModal()
        : props.history.push("/app");
    } else {
      props.closeModal && props.closeModal();
    }
  };

  useEffect(() => {
    if (!document.body.classList.contains("overflow-hidden")) {
      document.body.classList.add("overflow-hidden");
    }
    return () => {
      if (document.body.classList.contains("overflow-hidden")) {
        document.body.classList.remove("overflow-hidden");
      }
    };
  }, []);

  return (
    <div className="product-modal custom_product-modal productmodal-full">
      <div className="product-modal-inner">
        <div
          className="custom_product-modalBody"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="user-provisional-outer">
            <div className="product-modal-head">
              <h2>{props.heading && props.heading}</h2>
              <span
                className="product-close-modal-btn"
                onClick={handleModalClose}
              >
                <FontAwesomeIcon icon={solid("xmark")} />
              </span>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomProductModal;
