import Modal from "../../ui/Modal";
import React, { Component } from "react";
import "../../../styles/Connector.css";
import { connect } from "react-redux";
import {
  connectorConfig,
  connectorConfiguration,
} from "../../../actions/integrations";
import Loading from "../../ui/Loading";
import { fetchProducts } from "../../../actions/products";
import { withRouter } from "react-router-dom";
import OneLoginConfigurationForm from "./OneLoginConfigurationForm";
import X8ConfigurationForm from "./8X8ConfigurationForm";
import HubspotConfigurationForm from "./HubspotConfiguration";

const ConfigurationForm = {
  onelogin: OneLoginConfigurationForm,
  "8x8": X8ConfigurationForm,
  hubspot: HubspotConfigurationForm,
};

const formInitialValue = {
  onelogin: {
    oneloginDomain: "",
    client_id: "",
    client_secret: "",
    redirect_url: "",
    sup_client_id: "",
    sup_client_secret: "",
    sup_redirect_url: "",
  },
  "8x8": {
    api_key: "",
    customer_id: "",
    url: "https://platform-cloud.8x8.com/udi/customers/",
  },
  hubspot: {
    client_id: "",
    client_secret: "",
    redirect_url: "",
  },
};

class ConfigurationModel extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      modalToggle: false,
      isLoading: false,
      body: formInitialValue[match.params.topicURL],
      created_at: null,
      updated_at: null,
    };
  }

  fetchData = async () => {
    try {
      this.setState({ isLoading: true });
      const { data } = this.props;
      const { response } = await this.props.dispatch(
        connectorConfig(data.integration_id)
      );
      if (response && response.data && response.data.config) {
        this.setState({
          body: { ...this.state.body, ...response.data.config },
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleModalToggle = () => {
    const { modalToggle } = this.state;
    this.setState({ modalToggle: !modalToggle });
    if (!modalToggle) {
      this.fetchData();
    }
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    let bodyCopy = { ...this.state.body };
    if (name === "customer_id") {
      bodyCopy = {
        ...bodyCopy,
        url: `https://platform-cloud.8x8.com/udi/customers/${value}/scim/v2`,
      };
    }
    bodyCopy = { ...bodyCopy, [name]: value };
    this.setState({ body: bodyCopy });
  };

  handleSubmit = async () => {
    try {
      this.setState({ isLoading: true });
      // validatorForm(this.state.body);
      const {
        data: { integration_id },
        match: { params },
      } = this.props;

      const configBody = {
        ...this.state.body,
        redirect_url:
          this.state.body.sup_redirect_url || this.state.body.redirect_url,
      };

      const body = {
        name: params.topicURL,
        integration_id,
        config: JSON.stringify(configBody),
      };

      // return;

      await this.props.dispatch(connectorConfiguration(body));
      this.setState({ isLoading: false });
      this.props.dispatch(fetchProducts());
      this.handleModalToggle();
    } catch (error) {
      this.setState({ isLoading: false });
      this.handleModalToggle();
    }
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const { modalToggle, body, isLoading, updated_at, created_at } = this.state;
    const { handleChange, handleModalToggle, handleSubmit } = this;
    const ConfigForm = ConfigurationForm[params.topicURL];

    return (
      <div>
        <div className="connect">
          <button className="" onClick={handleModalToggle}>
            Configuration
          </button>
        </div>
        {modalToggle && (
          <Modal closeModal={handleModalToggle} outerClose>
            <div className="connector-configuration">
              <h1 className="text-center">Configuration</h1>
              {isLoading ? (
                <Loading />
              ) : ConfigForm ? (
                <ConfigForm
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  formValue={body}
                  updated_at={updated_at}
                  created_at={created_at}
                />
              ) : (
                <>
                  <h1>Configuration not found</h1>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(connect()(ConfigurationModel));
