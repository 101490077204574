// integration document mapping.
import { Documentation as GSuiteDoc } from "../components/documentation/gsuite";
import { Documentation as MicrosoftDoc } from "../components/documentation/microsoft365";
import { Documentation as SalesforceDoc } from "../components/documentation/salesforce";
import { Documentation as BoxDoc } from "../components/documentation/box";
import { Documentation as DropboxDoc } from "../components/documentation/dropbox";
import { Documentation as ADDoc } from "../components/documentation/activedirectory";
import { Documentation as zoomDoc } from "../components/documentation/zoom";
import { Documentation as x8Doc } from "../components/documentation/8x8";
import { Documentation as ateraDoc } from "../components/documentation/atera";
import { Documentation as OneLoginDoc } from "../components/documentation/OneLogin";
import { Documentation as HubSpotDoc } from "../components/documentation/hubspot";
import { Documentation as MineralDoc } from "../components/documentation/mineral";
import { Documentation as biz_libDoc } from "../components/documentation/bizlibrary";
import { Documentation as slackDoc } from "../components/documentation/slack";

export const ONEPOINTHCM = "ONEPOINTHCM";

// integration document map
export const documentMap = {
  gsuite: GSuiteDoc,
  microsoft365: MicrosoftDoc,
  salesforce: SalesforceDoc,
  box: BoxDoc,
  dropbox: DropboxDoc,
  activedirectory: ADDoc,
  "8x8": x8Doc,
  zoom: zoomDoc,
  atera: ateraDoc,
  onelogin: OneLoginDoc,
  hubspot: HubSpotDoc,
  biz_library:biz_libDoc,
  mineral:MineralDoc,
  slack:slackDoc,
};

// integration document map
export const integrationDocumentKeyMapping = {
  gsuite: `gsuite_integration_document`,
  microsoft365: `microsoft365_integration_document`,
  salesforce: `salesforce_integration_document`,
  box: `box_integration_document`,
  dropbox: `dropbox_integration_document`,
  activedirectory: `activedirectory_integration_document`,
  "8x8": `8x8_integration_document`,
  zoom: `zoom_integration_document`,
  atera: `atera_integration_document`,
  onelogin: `onelogin_integration_document`,
  hubspot: `hubspot_integration_document`,
  slack: `slack_integration_document`,
  mineral: `mineral_integration_document`,
  biz_library: `biz_library_integration_document`,
};

export const marketPlaceSlide = [
  {
    title: "Zoom",
    url: "zoom",
    description: `Create a zoom user for schedule and join meetings when an employee is hired, suspend them when they are terminated.`,
    img: "/images/zoom.png",
  },
  {
    title: "G Suite",
    url: "gsuite",
    description: `Create a G Suite User when an employee is hired, suspend their account when they are terminated.`,
    img: "/images/gsuite.png",
  },
  {
    title: "Office 365",
    url: "microsoft365",
    description: `Create a Microsoft Office 365 User when an employee is hired, delete their account when they are terminated.`,
    img: "/images/microsoft365.png",
  },
  {
    title: "Hubspot",
    url: "hubspot",
    description: `Create a HubSpot is a marketing, sales, and service platform that helps companies to attract visitors, convert leads, and close & retain customers.`,
    img: "/images/hubspot.png",
  },
  {
    title: "Atera",
    url: "atera",
    description: `Use for Synchronizing Mechatronic Systems in Real-Time Using FPGAs and Industrial Ethernet Communications.`,
    img: "/images/atera.png",
  },
  {
    title: "OneLogin",
    url: "onelogin",
    description: `users the ability to access the applications and other resources they need to do their job by logging in once to a single interface.`,
    img: "/images/onelogin.png",
  },
];

export const marketPlaceDetailContent = {
  gsuite: {
    title: "G Suite",
    url: "gsuite",
    description: `Create a G Suite User when an employee is hired, suspend their account when they are terminated. G Suite Basic edition is a suite of collaborative productivity apps that offers your business professional email, shared calendars,
     online document editing and storage, video meetings, and much more.
     Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque `,
    img: "images/gsuite.png",
  },
  hubspot: {
    title: "Hubspot",
    url: "hubspot",
    description: `Create a HubSpot is a marketing, sales, and service platform that helps companies to attract visitors, convert leads, and close & retain customers. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque `,
    img: "images/hubspot.png",
  },
  onelogin: {
    title: "OneLogin",
    url: "onelogin",
    description: `users the ability to access the applications and other resources they need to do their job by logging in once to a single interface. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque `,
    img: "images/onelogin.png",
  },
  atera: {
    title: "Atera",
    url: "atera",
    description: `Use for Synchronizing Mechatronic Systems in Real-Time Using FPGAs and Industrial Ethernet Communications. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque `,
    img: "images/atera.png",
  },
  microsoft365: {
    title: "Office 365",
    url: "microsoft365",
    description: `Create a Microsoft Office 365 User when an employee is hired, delete their account when they are terminated. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque `,
    img: "images/microsoft365.png",
  },
  zoom: {
    title: "Zoom",
    url: "zoom",
    description: `Create a zoom user for schedule and join meetings when an employee is hired, suspend them when they are terminated. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor at
            nemo fugit nesciunt sunt voluptatum vel voluptas culpa natus
            adipisci modi quo quam pariatur error a vero neque `,
    img: "images/zoom.png",
  },
};

// all connector show in the user provisioning product
export const integrationDisable = Object.keys(integrationDocumentKeyMapping);

// all week day array
export const weeklyDays = [
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
  { name: "Saturday" },
  { name: "Sunday" },
];

// export const integrationDisable = [
//   "gsuite",
//   "microsoft365",
//   "Salesforce",
//   "Box",
//   "Dropbox",
//   "Zoom",
//   "Atera",
//   "8x8",
//   "OneLogin",
//   "Hubspot",
//   "Mineral",
//   "Slack",
// ];

export const dataTableSortOption = [5, 10, 25, 50, 100, 200, 500, 1000];

export const productsAvailable = {
  [`user-provisioning`]: {
    url: "user-provisioning",
    label: "User Provisioning",
    integrations: [
      "gsuite",
      "microsoft365",
      "salesforce",
      "box",
      "dropbox",
      "activedirectory",
      // "BizLibrary"
    ],
  },
  jobs: {
    url: "jobs",
    label: "Job Board Integrations",
    integrations: ["indeed", "ziprecruiter"],
  },
  cloudpunch: {
    url: "cloudpunch",
    label: "CloudPunch - Windows Biometric",
    integrations: null,
  },
  webclock: {
    url: "webclock",
    label: "CloudPunch - Webclock",
    integrations: null,
  },
  multiple: {
    url: "multiple",
    label: "Multiple(EIN)",
    integrations: null,
  },
  imports: {
    url: "imports",
    label: "Imports",
    integrations: null,
  },
  mineral: {
    url: "mineral",
    label: "Mineral",
    integrations: null,
  },
  biz_library: {
    url: "biz_library",
    label: "BizLibrary",
    integrations: null,
  },
};

export const oldAppPath = "/old";

// new account time label
export const accountTimingOption = [
  { value: "date_hired", label: "Date Started" },
  {
    value: "date_started_sub_1",
    label: "1 Day Prior to Date Started",
  },
  {
    value: "date_started_sub_week",
    label: "1 week prior to start date",
  },
  {
    value: "date_started_sub_n",
    label: "N Days Prior to Date Started",
  },
  {
    value: "date_started_add_1",
    label: "1 Day After to Date Started",
  },
  {
    value: "date_started_add_week",
    label: "1 week after start date",
  },
  {
    value: "date_started_add_n",
    label: "N Days After to Date Started",
  },
];

// new email address settings
export const options = [
  {
    label: "Use Primary Email from OnePoint (must include at time of hire)",
    value: "use_op",
    needsDomain: false,
  },
  {
    label:
      "Autogenerate using pattern: {lowercase First Name}{lowercase Last Name}@{Domain Name}",
    value: "adjacent",
    needsDomain: true,
    example: "janedoe@yourcompany.com",
  },
  {
    label:
      "Autogenerate using pattern: {lowercase First Name}.{lowercase Last Name}@{Domain Name}",
    value: "period",
    needsDomain: true,
    example: "jane.doe@yourcompany.com",
  },
  {
    label:
      "Autogenerate using pattern: {lowercase First Inital}{lowercase Last Name}@{Domain Name}",
    value: "first_initial",
    needsDomain: true,
    example: "jdoe@yourcompany.com",
  },
  {
    label: "Use Username from OnePoint: {OnePoint Username}@{Domain Name}",
    value: "op_username",
    needsDomain: true,
    example: "opusername@yourcompany.com",
  },
  {
    label: "Use the email address generated by your G Suite Integration",
    value: "gsuite",
    needsDomain: false,
  },
  {
    label: "Use the email address generated by your Office 365 Integration",
    value: "microsoft365",
    needsDomain: false,
  },
];

// object for the put dynamic css class into the logs table row
export const dynamicCSSClass = {
  Error: "error",
  Info: "info",
  Success: "success",
};

export const showIntegrationConfigurationButton = [
  "slack",
  "8x8",
  "onelogin",
  "hubspot",
  "zoom",
  "mineral",
  "biz_library",
  "salesforce"
];

export const showIntegrationConfigurationUser = [
  "slack",
  "8x8",
  "onelogin",
  "hubspot",
  "atera",
];
