import React from "react";

export const Documentation = (
  <div className="documentationBody">
    <p>
      The slack User Provisioning integration allows OnePoint to become the
      driving force behind your Organization's slack instance. It will handle new
      hires, terminated employees, and changes to current employees.
    </p>
    <div className="subHead">Configuration</div>
    <p>
      first we need to configured client id, client secret and redirect URL for
      make the application connection, after the click into the connection
      button. we can test connection
    </p>
    <div className="subHead">New Hires</div>
    <p>
      When your company hires a new employee, the integration will create a new
      user in slack. Use the New User Templates setting above to select "Template
      Users" from your slack which are used to create new users with certain
      settings including address, group memberships, and ous.
    </p>
    <p>
      The new slack user gets properties set from values that were entered into
      OnePoint. These field mappings are listed below:
    </p>
    <ul>
      <li>Given Name = OP First Name</li>
      <li>Surname = OP Last Name</li>
      <li>SamAccountName = OP Username</li>
      <li>UserPrincipalName = OP Username @ (OU of template user)</li>
      <li>EmployeeID = OP Employee ID</li>
      <li>Address = Copied from template user</li>
      <li>MobilePhone = OP CellPhone</li>
      <li>OfficePhone = OP WorkPhone</li>
      <li>Title = OP Job Title (Default HR Job name)</li>
      <li>EmailAddress = Uses "New Email Address Settings" above</li>
    </ul>
    <div className="subHead">Terminations</div>
    <p>
      When an employee is terminated in OnePoint, they are Disabled in slack.{" "}
      <b>
        In order for this to work successfully, the employee's OnePoint username
        must equal the slack account's SAMAccountName.
      </b>
    </p>
    <div className="subHead">Employee Updates</div>
    <p>
      When HR or the employees themselves edit their profile info in OnePoint,
      this integration will try to update the slack account info. The fields that
      get updates are First and Last Name, EmployeeID, Email, MobilePhone,
      OfficePhone, and Title.
    </p>
  </div>
);
