import React from "react";
import MarketPlaceCard from "./MarketPlaceCard";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const responsive = [
  {
    breakpoint: 1500,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 1224,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 955,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 2,
    },
  },
  {
    breakpoint: 700,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const responsiveDashboard = [
  {
    breakpoint: 1700,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 1600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 1224,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 1,
    },
  },
];

const MarketPlace = ({ productData, dashboard }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    responsive: dashboard ? responsiveDashboard : responsive,
  };

  const marketPlaceSlide = productData.find(
    ({ product_url }) => product_url === "user-provisioning"
  );
  
  return (
    <div className="market-place mt-4">
      <h1>Connect Our Marketplace</h1>
      <Slider {...settings}>
        {marketPlaceSlide &&
          Array.isArray(marketPlaceSlide.integrations) &&
          marketPlaceSlide.integrations.map((item) => (
            <MarketPlaceCard {...item} key={item.url} />
          ))}
      </Slider>
    </div>
  );
};

export default MarketPlace;
