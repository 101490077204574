/* eslint-disable no-undef */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import BaseProduct from "../components/BaseProduct";

import UserProvisioningBody from "../components/UserProvisioningBody";
import JobsBody from "../components/JobsBody";
import WOTCBody from "../components/WOTCBody";
import CloudPunchBody from "../components/CloudPunchBody";
import GoogleHireBody from "../components/GoogleHireBody";

import EmployeeChooser from "../components/settings/EmployeeChooserSetting";
import SimpleTextSetting from "../components/settings/SimpleProductTextSetting";
import SelectSetting from "../components/settings/SelectSetting";
import BaseSetting from "../components/settings/BaseProductSetting";
import WOTCConstantsSetting from "../components/settings/WOTCConstantsSetting";
import IPRestriction from "../components/settings/IPRestrictionSetting";
import WebclockInputs from "../components/settings/WebclockInputs";
import WebclockAttestation from "../components/settings/WebclockAttestation";
import WebclockActions from "../components/settings/WebclockActions";

import Loading from "../components/ui/Loading";

import { saveSettings, refreshCaches } from "../actions/products";
import ExportsBody from "../components/exports/ExportsBody";
import BaseProductEIN from "../components/BaseProductEIN";
import MineralBody from "../components/MineralBody";
import BizLibraryBody from "../components/BizLibraryBody";


class ProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { successMsg: "" };

    this.renderSetting = this.renderSetting.bind(this);
    this.changeSetting = this.changeSetting.bind(this);
    this.renderProductBody = this.renderProductBody.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { productsAreUpdating } = this.props;
    const {
      updatingError: newUpdatingError,
      productsAreUpdating: newProductsAreUpdating,
    } = newProps;

    if (productsAreUpdating && !newProductsAreUpdating) {
      if (newUpdatingError === "") {
        this.setState({ successMsg: "Settings saved successfully" });
      }
    }
  }

  changeSetting(key, newValue) {
    const { companyProductId, dispatch, settings } = this.props;
    settings[key] = newValue;

    dispatch(saveSettings(settings, companyProductId));
  }

  renderSetting = (productURL, key, value) => {
    switch (key) {
      case "location_by_ip":
        return (
          <div className="psrow" key={key}>
            <BaseSetting
              title={`Location to IP Mapping`}
              tip={`Automatically add a Location Cost Center to each punch based off the employee's IP address. You must add your IP addresses to the External Id field of the cost centers in the Location tree.`}
            >
              <div className="settingsCont">
                <div className="bottom" style={{ marginTop: "10px" }}>
                  <SelectSetting
                    label={`Enabled:`}
                    description={""}
                    value={value.active ? "yes" : "no"}
                    onChange={(newChoice) =>
                      this.changeSetting(key, {
                        ...value,
                        active: newChoice.target.value === "yes" ? true : false,
                      })
                    }
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                  />
                  <SelectSetting
                    label={`Location-Mapped IP address required to punch:`}
                    description={
                      "Only allow employees from mapped IP addresses to punch in."
                    }
                    value={value.required ? "yes" : "no"}
                    onChange={(newChoice) =>
                      this.changeSetting(key, {
                        ...value,
                        required:
                          newChoice.target.value === "yes" ? true : false,
                      })
                    }
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                  />
                </div>
              </div>
            </BaseSetting>
          </div>
        );
      case "identify_first":
        return (
          <div className="psrow" key={key}>
            <BaseSetting title={`Employee Confirms Identity`} tip={""}>
              <div className="settingsCont">
                <div className="bottom" style={{ marginTop: "10px" }}>
                  <SelectSetting
                    label={`Employee first must confirm their identity before punching:`}
                    description={""}
                    value={value.active ? "yes" : "no"}
                    onChange={(newChoice) =>
                      this.changeSetting(key, {
                        active: newChoice.target.value === "yes" ? true : false,
                      })
                    }
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                  />
                </div>
              </div>
            </BaseSetting>
          </div>
        );
      case "actions":
        return (
          <div className="psrow" key={key}>
            <BaseSetting
              title={"Actions Available to Employees"}
              tip={
                "Choose what employees are able to do in addition to clocking in and out."
              }
            >
              <div className="settingsCont">
                <WebclockActions
                  value={value}
                  onChange={(news) => this.changeSetting(key, news)}
                />
              </div>
            </BaseSetting>
          </div>
        );
      case "preattestation":
        return (
          <div className="psrow" key={key}>
            <BaseSetting
              title={"Punch In Attestation"}
              tip={
                "Employees are asked questions when they clock in for the day. Answers can be recorded in OnePoint. In order to record answers, you must first configure your Time Entry Extra Fields."
              }
            >
              <div className="settingsCont">
                <WebclockAttestation
                  value={value}
                  onChange={(news) => this.changeSetting(key, news)}
                />
              </div>
            </BaseSetting>
          </div>
        );
      case "attestation":
        return (
          <div className="psrow" key={key}>
            <BaseSetting
              title={"Punch Out Attestation"}
              tip={
                "Employees are asked questions when they clock out for the day. Answers can be recorded in OnePoint. In order to record answers, you must first configure your Time Entry Extra Fields."
              }
            >
              <div className="settingsCont">
                <WebclockAttestation
                  value={value}
                  onChange={(news) => this.changeSetting(key, news)}
                />
              </div>
            </BaseSetting>
          </div>
        );
      case "inputs":
        return (
          <div className="psrow" key={key}>
            <BaseSetting
              title={"Employee Form Inputs"}
              tip={"What info your employees input to clock in."}
            >
              <div className="settingsCont">
                <WebclockInputs
                  value={value}
                  onChange={(news) => this.changeSetting(key, news)}
                />
              </div>
            </BaseSetting>
          </div>
        );
      case "ip":
        return (
          <div className="psrow" key={key}>
            <BaseSetting
              title={"IP Address Restrictions"}
              tip={"Control where your employees clock in from."}
            >
              <div className="settingsCont">
                <IPRestriction
                  value={value}
                  onChange={(news) => this.changeSetting(key, news)}
                />
              </div>
            </BaseSetting>
          </div>
        );
      case "notification_email_recipient":
        return (
          <div className="psrow" key={key}>
            <EmployeeChooser
              title={"Notification Email Recipients"}
              tip={
                "Choose employees in your company you want to recieve notification emails when Users get provisioned. This email will contain a summary of which accounts got created/removed and will include passwords for new accounts."
              }
              values={value}
              onChange={(newEmployees) => this.changeSetting(key, newEmployees)}
            />
          </div>
        );
      case "contact_email":
        return (
          <div className="psrow" key={key}>
            <SimpleTextSetting
              title={"Contact Email Address"}
              tip={
                "Required. Enter the email address you want receive communication from job boards."
              }
              value={value}
              onChange={(newAddress) => this.changeSetting(key, newAddress)}
            />
          </div>
        );
      case "hired_timing":
        return (
          <div className="psrow" key={key}>
            <BaseSetting title={"New Account Timing"} tip={""}>
              <div className="settingsCont">
                <div className="bottom" style={{ marginTop: "10px" }}>
                  <SelectSetting
                    label={""}
                    description={
                      "Select when you want new new hires' accounts to be provisioned relative to their Start and Hired Dates in OnePoint."
                    }
                    value={value.choice}
                    onChange={(newChoice) =>
                      this.changeSetting(key, {
                        choice: newChoice.target.value,
                      })
                    }
                    options={[
                      { value: "date_hired", label: "Date Hired" },
                      {
                        value: "date_started_sub_2",
                        label: "2 Days Prior to Date Started",
                      },
                      {
                        value: "date_started_sub_1",
                        label: "1 Day Prior to Date Started",
                      },
                      { value: "date_started", label: "Date Started" },
                    ]}
                  />
                </div>
              </div>
            </BaseSetting>
          </div>
        );
      case "wotc_form_constants":
        return (
          <div className="psrow" key={key}>
            <WOTCConstantsSetting
              value={value}
              onChange={(newSetting) => this.changeSetting(key, newSetting)}
              title={"Form Autofill Values"}
              tip={"These values will be used to fill out the WOTC forms."}
            ></WOTCConstantsSetting>
          </div>
        );

      default:
        return null;
    }
  };

  renderProductBody(productURL, integrations) {
    switch (productURL) {
      case "user-provisioning":
        return <UserProvisioningBody integrations={integrations} />;
      case "jobs":
        return <JobsBody integrations={integrations} />;
      case "wotc":
        return <WOTCBody integrations={integrations} />;
      case "cloudpunch":
        return <CloudPunchBody />;
      case "googlehire":
        return <GoogleHireBody />;
      case "imports":
        return <ExportsBody integrations={integrations} />;
      case "multiple":
        return <BaseProductEIN integrations={integrations} />;
      case "mineral":
        return <MineralBody integrations={integrations} />;
        case "biz_library":
        return <BizLibraryBody integrations={integrations} />;
      case "webclock":
        if (!this.props.shortname) {
          return null;
        }
        return (
          <div>
            <div>
              {/* eslint-disable react/jsx-no-target-blank */}
              <a
                style={{ color: "blue", fontWeight: "bold" }}
                target="_blank"
                href={`https://webclock.onehcm.com/${this.props.shortname}`}
              >
                Link to your company's Webclock
              </a>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => this.props.dispatch(refreshCaches(productURL))}
              >
                Refresh Caches
              </button>
              <span style={{ fontSize: "12px", marginLeft: "10px" }} className="paragraph">
                CAUTION: Refresh the Webclock caches only when necessary!
              </span>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      productName,
      integrations,
      productsAreFetching,
      settings,
      productsAreUpdating,
      updatingError,
      productURL,
      showAccessControl,
      companyProductId,
      accessControls,
      authorization,
      dispatch,
      history,
    } = this.props;
    const { successMsg } = this.state;

    if (productsAreFetching) {
      return <Loading />;
    }

    return (
      <BaseProduct
        name={productName}
        productsAreFetching={productsAreFetching}
        settings={settings}
        productsAreUpdating={productsAreUpdating}
        successMsg={successMsg}
        renderSetting={(key, value) =>
          this.renderSetting(productURL, key, value)
        }
        changeSetting={this.changeSetting}
        updatingError={updatingError}
        showAccessControl={showAccessControl}
        companyProductId={companyProductId}
        accessControls={accessControls}
        authorization={authorization}
        dispatch={dispatch}
        history={history}
        productURL={productURL}
      >
        {this.renderProductBody(productURL, integrations)}
      </BaseProduct>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    isFetching: productsAreFetching,
    isUpdating: productsAreUpdating,
    updatingError,
    userInfo,
    companyInfo,
  } = state.products;

  const { products, integrations } = state.entities;

  if (products === undefined) {
    return { productsAreFetching: true };
  }

  const { match } = ownProps;

  const productURL = match.params.productURL;

  const productData = products[productURL];

  const intsList =
    productData === undefined
      ? []
      : productData.integrations.map((intURL) => {
          return integrations[intURL];
        });

  return {
    productsAreFetching,
    productName: productData !== undefined && productData.product_name,
    productDescription:
      productData !== undefined && productData.product_description,
    integrations: intsList,
    settings: Object.assign(
      {},
      productData === undefined ? {} : productData.settings
    ),
    companyProductId:
      productData === undefined ? null : productData.company_product_id,
    productsAreUpdating,
    updatingError,
    productURL,
    showAccessControl:
      userInfo && (userInfo.is_company_admin || userInfo.is_system_admin),
    accessControls: productData !== undefined && productData.access_controls, 
    authorization: productData !== undefined && productData.authorization,
    shortname: companyInfo ? companyInfo.short_name : "",
  };
};

export default withRouter(connect(mapStateToProps)(ProductContainer));
