import React, { useEffect, useState } from 'react'
import '../../styles/DashboardStyle.css'
import DashboardLayout from '../../dashboard/components/layout/DashboardLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFetch from '../../dashboard/hooks/useFetch';
import Loading from '../ui/Loading';
import { Link } from 'react-router-dom';
function DashBoards() {
  const [salesforceData, loading] = useFetch();
  const [getData, setGetData] = useState('')
  const [announcement, setAnnouncement] = useState('')
  useEffect(() => {
    GetDashboardVal()
    GetAnnouncement()
  }, [])

  const GetDashboardVal = async () => {
    try {
      const payload = {
        endPoint: `/api/sf/user-info/pricellar@safefoodalliance.com`,
        method: "get",
      };
      const response = await salesforceData(payload);
      setGetData(response.data)

    } catch (error) {
      console.log("error", error);
    }

  }

  const GetAnnouncement = async () => {
    try {
      const payload = {
        endPoint: `/api/sf/user-dashboard-config`,
        method: "get",
      };
      const response = await salesforceData(payload);
      setAnnouncement(response)

    } catch (error) {
      console.log("error", error);
    }
  }
  // const monthNumber = announcement[0] && announcement[0].config.dates.split('-')[1] || 11-11-2024;
  const monthNumber = announcement[0] && announcement[0].config && announcement[0].config.dates && announcement[0].config.dates.split('-')[1] || '11';

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const monthName = monthNames[monthNumber - 1];
  const topicsData = announcement.length > 0 && announcement.map(topic => topic.config)


  return (
    <DashboardLayout>
      {loading ? <Loading /> :
        <div className='dash-outer'>
          <div className="dash-inner">
            <div className='dash-card'>
              <h3>{getData.Name}, Welcome!</h3>
              <p>Service Status/Alerts:</p>
              <button>Active</button>
            </div>
            <div className='servicerap-outer'>
              <h3>Details</h3>
              <p>Photo and contact information on clients service rep.</p>
              <h4>OnePoint info:</h4>
              <div className="servicerap-inner">
                <label>Phone:</label>
                <span>{getData.Phone}</span>
              </div>
              <div className="servicerap-inner">
                <label>Address:</label>
                <span>{getData && getData.MailingAddress.city}</span>
              </div>
            </div>
            <div className='othercontact-outer'>
              <h3>Other Contact</h3>
              <p>Meeting and information on  other user</p>
              <div className='othercontact-btn'>
                <button><span className='me-1'><FontAwesomeIcon icon={solid("calendar-days")} /></span>Schedule Meeting</button>
                <button><span className='me-1'><FontAwesomeIcon icon={solid("check-to-slot")} /></span>Submit Case</button>
                <button><span className='me-1'><FontAwesomeIcon icon={solid("comment-dots")} /></span>Chat now!</button>
              </div>
            </div>
          </div>
          <div className="announcement-outer">
            <div className="announcement-left">
              <h3>Announcements & dates to remember!</h3>
              <div className="announcementleft-inner">
                <div className='announcement-calender'>
                  <div className="announcementcalend-head">
                    <div className='cal_hole'></div>
                    {/* <span>Oct</span> */}
                    <h4>{monthName}</h4>
                    <div className='cal_hole_left'></div>
                  </div>
                  {/* <h1 className='date_tag'>{monthNumber}</h1> */}
                  <h1 className='date_tag'>
                    {announcement[0] && announcement[0].config && 
                    announcement[0].config.dates && announcement[0].config.dates.split('-')[0]}
                  </h1>

                </div>
                <div style={{ overflowY: 'auto', height: '200px' }}>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: announcement[0] && announcement[0].config.announcement }} />
                  </p>
                </div>
              </div>
            </div>
            <div className="announcement-right">
              <button>Forms</button>
              <Link to=''>www.google.com</Link>
              <Link to=''>www.hr.com</Link>
              <Link to=''>www.payroll.com</Link>
            </div>
          </div>
          <div className="projecttimeline-outer">
            <div className="projecttimeline-left">
              <h3>Project Timeline (Implementation)</h3>
              <p>Some default text to fill some space, and something more so there is more tex</p>
              <div className="projecttimeline-table">
                <table>
                  <thead>
                    <tr>
                      <th>Title 1</th>
                      <th>Title 2</th>
                      <th>Title 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Value 1</td>
                      <td>Value 2</td>
                      <td>Value 3</td>
                    </tr>
                    <tr>
                      <td>Value 4</td>
                      <td>Value 5</td>
                      <td>Value 6</td>
                    </tr>
                    <tr>
                      <td>Value 7</td>
                      <td>Value 8</td>
                      <td>Value 9</td>
                    </tr>
                    <tr>
                      <td>Value 7</td>
                      <td>Value 8</td>
                      <td>Value 9</td>
                    </tr> <tr>
                      <td>Value 7</td>
                      <td>Value 8</td>
                      <td>Value 9</td>
                    </tr> <tr>
                      <td>Value 7</td>
                      <td>Value 8</td>
                      <td>Value 9</td>
                    </tr> <tr>
                      <td>Value 7</td>
                      <td>Value 8</td>
                      <td>Value 9</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="hot_topics">
              <h3>Hot Topics</h3>
              <ul>
                <li>
                  <p>
                    <span className='arrow-rightWrapper'>
                      <FontAwesomeIcon icon={solid("arrow-right")} />
                    </span>
                    {topicsData && topicsData[1].topic_1}
                  </p>
                </li>
                <li>
                  <p>
                    <span className='arrow-rightWrapper'>
                      <FontAwesomeIcon icon={solid("arrow-right")} />
                    </span>
                    {topicsData && topicsData[1].topic_2}
                  </p>
                </li>
                <li>
                  <p>
                    <span className='arrow-rightWrapper'>
                      <FontAwesomeIcon icon={solid("arrow-right")} />
                    </span>
                    {topicsData && topicsData[1].topic_3}
                  </p>
                </li>
                <li>
                  <p>
                    <span className='arrow-rightWrapper'>
                      <FontAwesomeIcon icon={solid("arrow-right")} />
                    </span>
                    {topicsData && topicsData[1].topic_4}
                  </p>
                </li>
                <li>
                  <p>
                    <span className='arrow-rightWrapper'>
                      <FontAwesomeIcon icon={solid("arrow-right")} />
                    </span>
                    {topicsData && topicsData[1].topic_5}
                  </p>
                </li>
                <li>
                  <p>
                    <span className='arrow-rightWrapper'>
                      <FontAwesomeIcon icon={solid("arrow-right")} />
                    </span>
                    Some default text to fill some space, and something more so there is more text
                  </p>
                </li>

              </ul>
            </div>
          </div>
        </div>
      }
    </DashboardLayout>
  )
}

export default DashBoards
