import React from 'react'
import gif from '../../assets/images/loading-op.gif'
import Loader from "react-js-loader";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
const bgcolor = localStorage.getItem('loadercolor')
const loader = localStorage.getItem('loader')
const Loading = (props) => {
  const { dynamicloaderdata, dynamicloadercolor } = props
  const Ltype = dynamicloaderdata ? dynamicloaderdata : loader
  const checkloader = bgcolor === null ? '#002e6c' : dynamicloadercolor ? dynamicloadercolor : bgcolor;

  return (
    <>
      {
        loader === 'defaultloader' ? <img src={gif} alt="loading" className="loadinGif" /> :
          <Loader type={Ltype} className="loadinGif" bgColor={checkloader} color={'#000000'} size={125} />
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return { dynamicloaderdata: state.colortheme.dynamicloadertype, dynamicloadercolor: state.colortheme.dynamicloadercolor };
};

export default withRouter(connect(mapStateToProps)(Loading));
// export default Loading
