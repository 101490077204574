import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import Loading from "../../../components/ui/Loading";
import { getToken } from "../../../actions/auth";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HideShow from "../../../components/ui/HideShow";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const IntegrationLogs = (props) => {
  // const {
  //   match: { params },
  //   history,
  // } = props;
  const [showAccess] = useToggle(false);
  const [employeesEvents, setEmployeesEvents] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { token, userToken } = getToken();
  const fetchIntegrationEvents = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(`/api/emp/integration/event/status`, {
        headers: {
          Opusertoken: userToken,
          Authorization: token,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setEmployeesEvents(data);
      }
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("error", error);
    }
  };

  const handleCopyToClipBoard = (value) => {

    navigator.clipboard.writeText(value);
    toast.success("Link copy")
  }


  useEffect(() => {
    // if (employeesEvents.length === 0 && showAccess) {
    fetchIntegrationEvents();
    // }
  }, [showAccess]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    // className="product-setting-inner1"
    <div className="product-setting-inner email-notification mb-2">
      <HideShow
        label={<label>Employee Integrations Event Status</label>}
        body={
          <div>
            <p>New Hired/Fired employees status for all of the connectors.</p>
            <div className="emp-integration-setting">
              <div className="">
                <div className="">
                  {/* {loading ? (
                    <Loading />
                  ) : ( */}
                  <>
                    {/* <h1>{!isEmpty(employeesEvents)}</h1> */}
                    {!isEmpty(employeesEvents) &&
                      Array.isArray(employeesEvents)
                      ? employeesEvents && employeesEvents.map((item, index) => {
                        return (
                          <div
                            className={`employee-integration-event mb-1 ${!item.active && "inactive"
                              }`}
                            key={index}
                          >
                            <div className="my-flex my-align-item-center">
                              <HideShow
                                label={
                                  <div className="emp-integration-item">
                                    <div>
                                      <h3 className="mt-0">
                                        {item.first_name}
                                      </h3>
                                    </div>
                                    <small>{item.email}</small>
                                    <div className="my-flex my-justify-content-between data_space">
                                      <p className="mb-0 ">
                                        <span className="integration-event-status-green">
                                          Success :{" "}
                                        </span>
                                        <strong>{item.success}</strong>
                                      </p>
                                      <p className="mb-0">
                                        <span className="integration-event-status-red">
                                          Failed :{" "}
                                        </span>
                                        <strong>{item.failed}</strong>
                                      </p>
                                      <p className="mb-0">
                                        <span className="integration-event-status-red">
                                          Disabled :{" "}
                                        </span>
                                        <strong>{item.disabled}</strong>
                                      </p>
                                    </div>

                                    <Link target="_blank" to={`/emp-integration-status/${item.event_id}`}>
                                      <button>Open</button>
                                    </Link>

                                    <button className="ms-2 btn-copy" onClick={(event) => {
                                      event.stopPropagation()
                                      handleCopyToClipBoard(`https://connect.onehcm.com/emp-integration-status/${item.event_id}`)
                                    }}>Copy Link</button>

                                  </div>
                                }
                                body={
                                  <div className="desc-items">
                                    {item && item.integration_event_status && item.integration_event_status.map(
                                      (items) => (
                                        <>
                                          <p className="mb-0">
                                            <strong>Name : </strong>{" "}
                                            {items.Name}{" "}
                                          </p>
                                          {items.Status === "SUCCESS" ? (
                                            <p className="mb-0">
                                              <strong>Status : </strong>
                                              <span className="integration-event-status-green">
                                                {" "}
                                                {items.Status}{" "}
                                              </span>
                                            </p>
                                          ) : (
                                            <p className="mb-0">
                                              <strong>Status : </strong>
                                              <span className="integration-event-status-red">
                                                {" "}
                                                {items.Status}{" "}
                                              </span>
                                            </p>
                                          )}
                                          <p className="mb-0">
                                            <strong>Description : </strong>{" "}
                                            {items.Description}{" "}
                                          </p>
                                          <hr />
                                        </>
                                      )
                                    )}
                                  </div>

                                  // <table>
                                  //   <thead>
                                  //     <tr>
                                  //       <th>Name</th>
                                  //       <th>Status</th>
                                  //       <th>Description</th>
                                  //     </tr>
                                  //   </thead>
                                  //   <tbody>
                                  //     {item.integration_event_status.map((items, indexs) => (
                                  //       <tr key={indexs}>
                                  //         <td>{items.Name}</td>
                                  //         {items.Status ==="SUCCESS" ? <td className="integration-event-status-green">{items.Status}</td> :  <td className="integration-event-status-red">{items.Status}</td>}
                                  //         <td className="settings-desc">{items.Description}</td>
                                  //       </tr>
                                  //     ))}
                                  //   </tbody>
                                  // </table>
                                }
                              />
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

const mapStateToProps = (state, newProps) => {
  const { products, integrations } = state.entities;
  const {
    isFetching: productsAreFetching,
    isUpdating: productsAreUpdating,
    userInfo,
  } = state.products;

  const { match } = newProps;
  const prodURL = match.url.split("/");

  const productData = products[prodURL[prodURL.length - 1]];

  const intsList =
    productData === undefined
      ? []
      : productData && productData.integrations.map((intURL) => {
        return integrations[intURL];
      });

  return {
    integrations: intsList,
    productsAreFetching,
    productsAreUpdating,
    settings: productData ? productData.settings : {},
    companyProductId:
      productData === undefined ? null : productData.company_product_id,
    accessControls: productData !== undefined && productData.access_controls,
    userInfo,
  };
};

export default withRouter(connect(mapStateToProps)(IntegrationLogs));
