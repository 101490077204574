import React, { Component } from "react";
import "../styles/BaseProduct.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Autocomplete from "react-autocomplete";
import {
  editAssociationCompanyById,
  getAssociationCustReportDelete,
  getAssociationCustReportNameDelete,
} from "../actions/cloudpunch";
import toast from "react-hot-toast";
import { isEmpty } from "lodash";
import Loading from "./ui/Loading";

class EditCompanyModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      companies: [],
      companyDropdown: [],
      associateCompanyValue: "",
      company_id: "",
      itemId: "",
      titleRequire: "",
      duplicateField: "",
      associateCustomReportName: "",
      associateCustomReportNameValid: "",
      customReportNamesData: [],
      customReportModel: false,
      customReportOpenIndex: null,
      customReportSelectedCompany: null,
      associateCustomReportId: "",
      associateCustomReportIdDuplicate: "",
      isLoading: false,
      isCustomLoading: false,
    };
  }

  handleCustomReportModelToggle = (index) => {
    const { companies } = this.props;

    let stateData = {
      customReportModel: !this.state.customReportModel,
      customReportOpenIndex: index,
      associateCustomReportIdDuplicate: "",
      associateCustomReportNameValid: "",
    };

    if (companies.length !== 0) {
      stateData.customReportSelectedCompany = companies[0];
    }

    this.setState(stateData);
  };

  componentDidMount() {
    const primaryCompany = {
      name: this.props.companyInfo.name,
      company_id: this.props.userInfo.company_id,
      p_company_id: 1,
    };

    this.setState({
      customReportSelectedCompany: primaryCompany,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reports.length !== this.props.reports.length) {
      this.setState({
        customReportNamesData: this.props.reports,
      });
    }
  }

  handleSelectOnChange = async (e) => {
    this.setState({ associateCompanyValue: e.target.value });
    try {
      const data = await this.props.searchCompanies(e.target.value);
      this.setState({ companyDropdown: Object.values(data.response) });
    } catch (e) {
      this.setState({ companyDropdown: "" });
    }
  };

  handleAddToList = async () => {
    const { companies, associateCompanyValue, company_id } = this.state;
    if (!company_id) {
      return;
    }
    this.setState({
      ...this.state,
      companies: [
        ...companies,
        {
          title: associateCompanyValue,
          p_company_id: 0,
          company_id: company_id,
        },
      ],
      associateCompanyValue: "",
      company_id: "",
    });
  };

  handleAddToListDelete = (index) => {
    const { companies } = this.state;
    companies.splice(index, 1);
    this.setState({
      ...this.state,
      companies: [...companies],
    });
  };

  //Sync report into the object.
  handleReportChange = async (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      duplicateField: "",
    });
    // eslint-disable-next-line
    const emptyString = /^[a-zA-Z]+[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;
    const titleCheck = emptyString.test(this.props.title);
    const companyLengthCheck = this.props.companies.length < 2;
    const { customReportNamesData } = this.state;

    if (!titleCheck) {
      this.setState({
        titleRequire: "Title not allowed",
        duplicateField: "",
        isLoading: false,
      });
      if (companyLengthCheck) {
        this.setState({
          duplicateField: "Please add one or more company",
        });
      }
      return;
    }
    if (companyLengthCheck) {
      this.setState({
        duplicateField: "Please add one or more company",
        company_id: "",
        isLoading: false,
        titleRequire: "",
      });
      return;
    }
    const data = {
      association_id: this.props.associationId,
      title: this.props.title,
      companies: this.props.companies,
      custom_reports: customReportNamesData,
    };

    try {
      await this.props.dispatch(editAssociationCompanyById(data));
    } catch (error) {}
    if (this.props.editAssociationsCompanyError) {
      this.setState({
        isLoading: false,
        titleRequire: "Association already added",
        duplicateField: "",
      });
      return;
    }

    this.props.Modaltoggle(false);
    this.props.fetchAssociationsList();
    this.setState({
      isLoading: false,
      titleRequire: "",
      duplicateField: "",
    });
    toast.success("Association updated successfully!");
    // this.props.dispatch(fetchAssociations(this.props.userInfo.company_id));
  };

  handleAddCustomReportName = () => {
    const { associateCustomReportName, customReportNamesData } = this.state;

    // eslint-disable-next-line
    const emptyString = /^[a-zA-Z]+[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;
    const customReportNameCheck = emptyString.test(associateCustomReportName);

    if (!customReportNameCheck) {
      this.setState({
        associateCustomReportNameValid: "Report name is required!",
      });
      return;
    }

    const findReportNameIndex = customReportNamesData.findIndex(
      ({ report_name }) => report_name === associateCustomReportName
    );

    if (findReportNameIndex > -1) {
      this.setState({
        associateCustomReportNameValid: "Duplicate field not allowed!",
      });
      return;
    }

    this.setState({
      customReportNamesData: [
        ...customReportNamesData,
        {
          report_name: associateCustomReportName,
          reports: [],
          isNew: true,
        },
      ],
      associateCustomReportName: "",
      associateCustomReportNameValid: "",
    });
  };

  //Handle Add custom report into the array.
  handleAddReports = async () => {
    const {
      associateCustomReportId,
      customReportSelectedCompany,
      customReportNamesData,
      customReportOpenIndex,
    } = this.state;

    const reportCopy = [...customReportNamesData];

    if (isEmpty(associateCustomReportId)) {
      this.setState({
        associateCustomReportIdDuplicate: "Report Id is required!",
      });
      return;
    }

    reportCopy[customReportOpenIndex].reports = isEmpty(
      reportCopy[customReportOpenIndex].reports
    )
      ? []
      : reportCopy[customReportOpenIndex].reports;

    const findReportIndex = reportCopy[customReportOpenIndex].reports.findIndex(
      ({ report_id, company_id }) =>
        report_id === Number(associateCustomReportId) &&
        company_id === String(customReportSelectedCompany.company_id)
    );

    if (findReportIndex > -1) {
      this.setState({
        associateCustomReportIdDuplicate: "Report Id already exist!",
      });
      return;
    }

    reportCopy[customReportOpenIndex].reports = [
      ...reportCopy[customReportOpenIndex].reports,
      {
        report_id: Number(associateCustomReportId),
        name: customReportSelectedCompany.name,
        company_id: String(customReportSelectedCompany.company_id),
        isNew: true,
      },
    ];

    this.setState({
      customReportNamesData: [...reportCopy],
      associateCustomReportId: "",
      associateCustomReportIdDuplicate: "",
    });
  };

  handleChangeSelectCompany = (e) => {
    const { value } = e.target;
    this.setState({ customReportSelectedCompany: JSON.parse(value) });
  };

  handleDeleteReportID = (reportData, index) => async () => {
    const { customReportOpenIndex, customReportNamesData } = this.state;
    const { report_name, association_id } =
      customReportNamesData[customReportOpenIndex];
    const copy = [...customReportNamesData];

    if (reportData.isNew) {
      copy[customReportOpenIndex].reports.splice(index, 1);

      this.setState({ customReportNamesData: copy });
      return;
    }

    const body = {
      association_id: Number(association_id),
      company_id: Number(reportData.company_id),
      report_id: reportData.report_id,
      report_name: report_name,
    };

    this.setState({ isCustomLoading: true });

    await this.props.dispatch(getAssociationCustReportDelete(body));

    copy[customReportOpenIndex].reports.splice(index, 1);
    this.setState({ customReportNamesData: copy, isCustomLoading: false });
  };

  handleDeleteReportName = (reportData, index) => async () => {
    const { customReportNamesData } = this.state;
    const copy = [...customReportNamesData];

    const { association_id, report_name } = reportData;

    if (copy[index].isNew) {
      copy.splice(index, 1);

      this.setState({ customReportNamesData: copy });
      return;
    }

    const body = {
      association_id: Number(association_id),
      report_name: report_name,
    };

    this.setState({ isLoading: true });

    await this.props.dispatch(getAssociationCustReportNameDelete(body));

    copy.splice(index, 1);
    this.setState({ customReportNamesData: copy, isLoading: false });
  };

  handleCompanyDelete = () => {};

  render() {
    const { companies, companiesLoading } = this.props;
    const {
      associateCustomReportName,
      customReportNamesData,
      customReportModel,
      customReportSelectedCompany,
      associateCustomReportId,
      customReportOpenIndex,
      associateCustomReportNameValid,
      associateCustomReportIdDuplicate,
      isLoading,
      isCustomLoading,
    } = this.state;

    return (
      <>
        {this.props.toggleModal && (
          <div id="modalOuter" className="custom-modal">
            <div id="myModal" className="custom-modal">
              <div className="modal-content modal-contentcstm">
                <span
                  onClick={() => {
                    this.props.Modaltoggle(false);
                    this.setState({ titleRequire: "" });
                  }}
                  className="close"
                >
                  &times;
                </span>
                <h1 className="text-center schedule-confi">Edit Association</h1>
                {isLoading || companiesLoading ? (
                  <Loading />
                ) : (
                  <form onSubmit={this.handleSubmit}>
                    <div className="mt-2 mbcstm">
                      <label htmlFor="title">Title:</label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={this.props.title}
                        onChange={this.props.handleOnchange}
                        className="formcontrol"
                        placeholder="Title (max 50 character)"
                      />
                      <p className="color-red">{this.state.titleRequire}</p>
                    </div>

                    <div className="">
                      <label htmlFor="associate_company">
                        Associate Company:
                      </label>
                    </div>
                    <div className="associatecompany mbcstm">
                      <div className="autocomplete">
                        <Autocomplete
                          className="formcontrol searchinput"
                          getItemValue={(item) => {
                            return JSON.stringify(item);
                          }}
                          wrapperStyle={{
                            display: "block",
                          }}
                          items={[...this.state.companyDropdown]}
                          renderItem={(item, isHighlighted) => (
                            <div
                              key={item.ID}
                              style={{
                                background: isHighlighted
                                  ? "lightgray"
                                  : "white",
                              }}
                            >
                              {item.name}
                            </div>
                          )}
                          value={this.state.associateCompanyValue}
                          onChange={this.handleSelectOnChange}
                          onSelect={(e) => {
                            const data = JSON.parse(e);
                            this.setState({
                              associateCompanyValue: data.name,
                              company_id: data.ID,
                            });
                          }}
                        />
                      </div>

                      <button
                        className="addcompanybtn"
                        type="button"
                        // disabled={isEmpty(associateCompanyValue) ? true : false}
                        onClick={() => {
                          this.props.handleAddToList({
                            name: this.state.associateCompanyValue,
                            company_id: this.state.company_id,
                            p_company_id: 0,
                            isNew: true,
                          });
                          this.setState({
                            company_id: "",
                            associateCompanyValue: "",
                            duplicateField: "",
                          });
                        }}
                      >
                        +
                      </button>
                    </div>
                    <p className="color-red">
                      {this.state.duplicateField || this.props.duplicateField}
                    </p>

                    <div className="mt-2 mbcstm">
                      <label htmlFor="title">Custom Report Name</label>
                      <div className="custom-report-input">
                        <input
                          type="text"
                          id="title"
                          value={associateCustomReportName}
                          name={"associateCustomReportName"}
                          onChange={this.handleReportChange}
                          className="formcontrol"
                          placeholder="Custom Report Name"
                        />
                        <button
                          className="addcompanybtn"
                          type="button"
                          // disabled={handleAddCustomReportName}
                          onClick={this.handleAddCustomReportName}
                        >
                          +
                        </button>
                      </div>
                      {associateCustomReportNameValid && (
                        <p className="color-red">
                          {associateCustomReportNameValid}
                        </p>
                      )}
                    </div>

                    <div className="col-12 mbcstm asscmp-a-table-scroll">
                      <table className="table tablecustompop">
                        <thead>
                          <tr>
                            <th scope="col">Company Name</th>
                            <th scope="col"></th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {companies.map((val, ind) => {
                            return (
                              <tr key={ind}>
                                <td>{val.name}</td>
                                <td>
                                  {val.p_company_id ? (
                                    <span className="badgecolor">Primary</span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {val.p_company_id ? (
                                    <button
                                      type="button"
                                      className="cstmdlt"
                                      disabled={true}
                                      onClick={() =>
                                        this.handleAddToListDelete(ind)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="ddd-color"
                                        icon={solid("trash-alt")}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="cstmdlt"
                                      disabled={false}
                                      onClick={() =>
                                        this.props.handleAddToListDelete(ind)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={solid("trash-alt")}
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-12 mbcstm asscmp-a-table-scroll">
                      <table className="table tablecustompop">
                        <thead>
                          <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Report Name</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(customReportNamesData) &&
                            customReportNamesData.map((val, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>{ind + 1}</td>
                                  <td>{val.report_name}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="theme-btn"
                                      onClick={() =>
                                        this.handleCustomReportModelToggle(ind)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className=""
                                        icon={solid("eye")}
                                      />
                                    </button>
                                    <button
                                      type="button"
                                      style={{ marginLeft: "10px" }}
                                      className="theme-btn"
                                      onClick={this.handleDeleteReportName(
                                        val,
                                        ind
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon={solid("trash-alt")}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="row d-flex1">
                      <div className="col-12">
                        <input
                          // onClick={() => this.props.Modaltoggle(false)}
                          className="submitbtncstm"
                          type="submit"
                          value="Update"
                          disabled={isLoading}
                        />

                        <input
                          onClick={() => {
                            this.props.Modaltoggle(false);
                            this.setState({ titleRequire: "" });
                          }}
                          className="submitbtncstm cancelbtncstm"
                          type="button"
                          value="Cancel"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
        {customReportModel && (
          <div
            className="modalOuter"
            // onClick={() => this.handleCustomReportModelToggle(null)}
          >
            <div
              className="modalBody"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <din>
                <h1>Custom Report</h1>
                {isCustomLoading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="custom-report-container">
                      <div className="select-cus-report">
                        <div>
                          <label>Association Company</label>
                        </div>
                        <select
                          value={JSON.stringify(customReportSelectedCompany)}
                          onChange={this.handleChangeSelectCompany}
                        >
                          {!isEmpty(companies) &&
                            companies.map((com) => (
                              <option value={JSON.stringify(com)}>
                                {com.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="custom-report-s-input">
                        <div>
                          <label htmlFor="s">Report ID</label>
                        </div>
                        <input
                          type="number"
                          placeholder="Report ID"
                          name="associateCustomReportId"
                          value={associateCustomReportId}
                          onChange={this.handleReportChange}
                        />
                      </div>
                      <div className="cus-add-btn">
                        <button type="button" onClick={this.handleAddReports}>
                          <FontAwesomeIcon className="" icon={solid("plus")} />
                        </button>
                      </div>
                    </div>
                    {!isEmpty(associateCustomReportIdDuplicate) && (
                      <p className="color-red">
                        {associateCustomReportIdDuplicate}
                      </p>
                    )}
                    <div className="cus-reportId-table">
                      <div className="col-12 mbcstm asscmp-a-table-scroll">
                        <table className="table tablecustompop">
                          <thead>
                            <tr>
                              <th scope="col">S.NO</th>
                              <th scope="col">Company Name</th>
                              <th scope="col">Report ID</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isEmpty(
                              customReportNamesData[customReportOpenIndex]
                                .reports
                            ) &&
                              customReportNamesData[
                                customReportOpenIndex
                              ].reports.map((val, ind) => {
                                return (
                                  <tr key={ind}>
                                    <td>{ind + 1}</td>
                                    <td>{val.name}</td>
                                    <td>{val.report_id}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="theme-btn"
                                        onClick={this.handleDeleteReportID(
                                          val,
                                          ind
                                        )}
                                      >
                                        <FontAwesomeIcon
                                          icon={solid("trash-alt")}
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={() => this.handleCustomReportModelToggle(null)}
                      >
                        Back
                      </button>
                    </div>
                  </>
                )}
              </din>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default EditCompanyModel;
