import React, { Component } from "react";
import "../styles/BaseProduct.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Autocomplete from "react-autocomplete";
import { isEmpty } from "lodash";
import { postAssociation } from "../actions/cloudpunch";
import toast from "react-hot-toast";

class AddCompanyModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      companies: [],
      reports: [],
      companyDropdown: [],
      associateCompanyValue: "",
      associateCustomReportId: "",
      associateCustomReportIdDuplicate: "",
      associateCustomReportName: "",
      associateCustomReportNameValid: "",
      company_id: "",
      duplicateField: "",
      titleRequire: "",
      isLoading: false,
      customReportModel: false,
      customReportSelectedCompany: null,
      customReportNamesData: [],
      customReportOpenIndex: null,
    };
  }

  handleCustomReportModelToggle = (index) => {
    this.setState({
      customReportModel: !this.state.customReportModel,
      customReportOpenIndex: index,
      associateCustomReportNameValid: "",
      associateCustomReportIdDuplicate: "",
    });
  };

  handleCustomReportDelete = (index) => () => {
    const { customReportNamesData } = this.state;
    const copy = [...customReportNamesData];
    copy.splice(index, 1);
    this.setState({ customReportNamesData: copy });
  };

  handleCustomReportDeleteReportID = (index) => () => {
    const { customReportNamesData, customReportOpenIndex } = this.state;
    const copy = [...customReportNamesData];
    copy[customReportOpenIndex].reports.splice(index, 1);
    this.setState({ customReportNamesData: copy });
  };

  componentDidMount() {
    const { companies } = this.state;

    const primaryCompany = {
      title: this.props.companyInfo.name,
      company_id: this.props.userInfo.company_id,
      p_company_id: 1,
    };

    this.setState({
      ...this.state,
      companies: [...companies, primaryCompany],
      customReportSelectedCompany: primaryCompany,
      associateCompanyValue: "",
    });
  }

  handleOnchange = (e) => {
    const { name, value, checked, type } = e.target;
    const { searchCompanies } = this.props;
    // eslint-disable-next-line
    if (name == "company_id") {
      searchCompanies(value);
    }
    this.setState({
      ...this.state,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    });
  };

  handleAddToList = async () => {
    const { companies, associateCompanyValue, company_id } = this.state;
    const companyExists = this.state.companies.some(
      (company) => company.company_id === company_id
    );

    if (!company_id) {
      this.setState({
        duplicateField: "Company not found",
        company_id: "",
      });
      return;
    }

    if (companyExists) {
      this.setState({
        duplicateField: "Company already exists",
        company_id: "",
      });
      return;
    }

    this.setState({
      ...this.state,
      companies: [
        ...companies,
        {
          title: associateCompanyValue,
          p_company_id: 0,
          company_id: company_id,
        },
      ],
      associateCompanyValue: "",
      company_id: "",
      duplicateField: "",
    });
  };

  //Handle Add custom report into the array.
  handleAddReports = async () => {
    const {
      associateCustomReportId,
      customReportSelectedCompany,
      customReportNamesData,
      customReportOpenIndex,
    } = this.state;
    // customReportOpenIndex;
    const reportCopy = [...customReportNamesData];

    if (isEmpty(associateCustomReportId)) {
      this.setState({
        associateCustomReportIdDuplicate: "Report Id is required!",
      });
      return;
    }
    const findReportIndex = reportCopy[customReportOpenIndex].reports.findIndex(
      ({ report_id, company_id }) =>
        report_id === Number(associateCustomReportId) &&
        company_id === String(customReportSelectedCompany.company_id)
    );

    if (findReportIndex > -1) {
      this.setState({
        associateCustomReportIdDuplicate: "Report Id already exist!",
      });
      return;
    }

    reportCopy[customReportOpenIndex].reports = [
      ...reportCopy[customReportOpenIndex].reports,
      {
        report_id: Number(associateCustomReportId),
        title: customReportSelectedCompany.title,
        company_id: String(customReportSelectedCompany.company_id),
      },
    ];

    this.setState({
      customReportNamesData: [...reportCopy],
      associateCustomReportId: "",
      associateCustomReportIdDuplicate: "",
    });
  };

  //Sync report into the object.
  handleReportChange = async (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleAddToListDelete = (index) => {
    const { companies } = this.state;
    companies.splice(index, 1);
    this.setState({
      ...this.state,
      companies: [...companies],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const {
      associateCompanyValue,
      companyDropdown,
      company_id,
      title,
      customReportNamesData,
      reports,
      customReportSelectedCompany,
      duplicateField,
      isLoading,
      titleRequire,
      ...formValue
    } = this.state;

    // eslint-disable-next-line
    const emptyString = /^[a-zA-Z]+[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;
    const titleCheck = emptyString.test(this.state.title);
    const companyLengthCheck = this.state.companies.length < 2;

    if (!titleCheck) {
      this.setState({
        titleRequire: "Title not allowed",
        duplicateField: "",
        isLoading: false,
      });
      if (companyLengthCheck)
        this.setState({
          duplicateField: "Please add one or more company",
        });
      return;
    }
    if (companyLengthCheck) {
      this.setState({
        duplicateField: "Please add one or more company",
        company_id: "",
        titleRequire: "",
        isLoading: false,
      });
      return;
    }
    const { response } = await this.props.dispatch(
      postAssociation({
        ...formValue,
        title,
        custom_reports: customReportNamesData,
      })
    );

    if (this.props.associationspostError) {
      this.setState({
        titleRequire: "Association already added",
        company_id: "",
        isLoading: false,
      });
      return;
    }
    if (response.association_id) {
      this.props.fetchAssociationsList();
      // this.props.dispatch(fetchAssociations(this.props.userInfo.company_id));
      this.props.Modaltoggle();
      this.setState({
        isLoading: false,
        title: "",
        titleRequire: "",
        duplicateField: "",
        companies: [
          {
            title: this.props.companyInfo.name,
            company_id: this.props.userInfo.company_id,
            p_company_id: 1,
          },
        ],
      });
      toast.success("Association created successfully!");
    }
    this.setState({
      isLoading: false,
    });
  };

  handleSelectOnChange = async (e) => {
    this.setState({ associateCompanyValue: e.target.value });
    try {
      const data = await this.props.searchCompanies(e.target.value);
      this.setState({ companyDropdown: Object.values(data.response) });
    } catch (e) {
      this.setState({ companyDropdown: "" });
    }
  };

  handleClose = () => {
    this.setState({
      title: "",
      titleRequire: "",
      duplicateField: "",
      companies: [
        {
          title: this.props.companyInfo.name,
          company_id: this.props.userInfo.company_id,
          p_company_id: 1,
        },
      ],
    });
    this.props.Modaltoggle();
  };

  handleAddCustomReportName = () => {
    const { associateCustomReportName, customReportNamesData } = this.state;

    // eslint-disable-next-line
    const emptyString = /^[a-zA-Z]+[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;
    const customReportNameCheck = emptyString.test(associateCustomReportName);

    if (!customReportNameCheck) {
      this.setState({
        associateCustomReportNameValid: "Report name is required!",
      });
      return;
    }

    const findReportNameIndex = customReportNamesData.findIndex(
      ({ report_name }) => report_name === associateCustomReportName
    );

    if (findReportNameIndex > -1) {
      this.setState({
        associateCustomReportNameValid: "Duplicate field not allowed!",
      });
      return;
    }

    this.setState({
      customReportNamesData: [
        ...customReportNamesData,
        {
          report_name: associateCustomReportName,
          reports: [],
        },
      ],
      associateCustomReportName: "",
      associateCustomReportNameValid: "",
    });
  };

  handleChangeSelectCompany = (e) => {
    const { value } = e.target;
    this.setState({ customReportSelectedCompany: JSON.parse(value) });
  };

  render() {
    const {
      title,
      companies,
      associateCompanyValue,
      associateCustomReportId,
      associateCustomReportName,
      customReportModel,
      customReportNamesData,
      customReportSelectedCompany,
      associateCustomReportNameValid,
      customReportOpenIndex,
      associateCustomReportIdDuplicate,
    } = this.state;

    return (
      <>
        {this.props.toggleModal && (
          <div id="modalOuter" className="custom-modal">
            <div id="myModal" className="custom-modal">
              <div className="modal-content modal-contentcstm">
                <span onClick={this.handleClose} className="close">
                  &times;
                </span>
                <div className="modal-scroll">
                  <h1 className="text-center">Add Association</h1>
                  <form onSubmit={this.handleSubmit}>
                    <div className="mt-2 mbcstm">
                      <label htmlFor="title">Title*</label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        onChange={this.handleOnchange}
                        className="formcontrol"
                        placeholder="Title (max 50 character)"
                      />
                      <p className="color-red">{this.state.titleRequire}</p>
                    </div>

                    <div className="">
                      <label htmlFor="associate_company">
                        Associate Company:
                      </label>
                    </div>

                    <div className="associatecompany mbcstm">
                      <div className="autocomplete">
                        <Autocomplete
                          className="formcontrol searchinput"
                          getItemValue={(item) => {
                            return JSON.stringify(item);
                          }}
                          wrapperStyle={{
                            display: "block",
                          }}
                          items={[...this.state.companyDropdown]}
                          renderItem={(item, isHighlighted) => (
                            <div
                              key={item.ID}
                              style={{
                                background: isHighlighted
                                  ? "lightgray"
                                  : "white",
                              }}
                            >
                              {item.name}
                            </div>
                          )}
                          value={this.state.associateCompanyValue}
                          onChange={this.handleSelectOnChange}
                          onSelect={(e) => {
                            const data = JSON.parse(e);
                            this.setState({
                              associateCompanyValue: data.name,
                              company_id: data.ID,
                            });
                          }}
                        />
                      </div>

                      <button
                        className="addcompanybtn"
                        type="button"
                        disabled={isEmpty(associateCompanyValue) ? true : false}
                        onClick={this.handleAddToList}
                      >
                        +
                      </button>
                    </div>

                    <p className="color-red">{this.state.duplicateField}</p>

                    <div className="mt-2 mbcstm">
                      <label htmlFor="title">Custom Report Name</label>
                      <div className="custom-report-input">
                        <input
                          type="text"
                          id="title"
                          value={associateCustomReportName}
                          name={"associateCustomReportName"}
                          onChange={this.handleReportChange}
                          className="formcontrol"
                          placeholder="Custom Report Name"
                        />
                        <button
                          className="addcompanybtn"
                          type="button"
                          // disabled={handleAddCustomReportName}
                          onClick={this.handleAddCustomReportName}
                        >
                          +
                        </button>
                      </div>
                      {associateCustomReportNameValid && (
                        <p className="color-red">
                          {associateCustomReportNameValid}
                        </p>
                      )}
                    </div>

                    <div className="col-12 mbcstm asscmp-a-table-scroll">
                      <table className="table tablecustompop">
                        <thead>
                          <tr>
                            <th scope="col">Company Name</th>
                            <th scope="col"></th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(companies) &&
                            companies.map((val, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>{val.title}</td>
                                  <td>
                                    {val.p_company_id ? (
                                      <span className="badgecolor">
                                        Primary
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {val.p_company_id ? (
                                      <>
                                        <button
                                          type="button"
                                          className="cstmdlt"
                                          disabled={true}
                                          onClick={() =>
                                            this.handleAddToListDelete(ind)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            className="ddd-color"
                                            icon={solid("trash-alt")}
                                          />
                                        </button>
                                        {/* {associateCustomReportName && (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.handleCustomReportModelToggle(
                                              val
                                            )
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={solid("plus")}
                                          />
                                        </button>
                                      )} */}
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          type="button"
                                          className="cstmdlt"
                                          disabled={false}
                                          onClick={() =>
                                            this.handleAddToListDelete(ind)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={solid("trash-alt")}
                                          />
                                        </button>
                                        {/* {associateCustomReportName && (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.handleCustomReportModelToggle(
                                              val
                                            )
                                          }
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={solid("plus")}
                                          />
                                        </button>
                                      )} */}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-12 mbcstm asscmp-a-table-scroll">
                      <table className="table tablecustompop">
                        <thead>
                          <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Report Name</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(customReportNamesData) &&
                            customReportNamesData.map((val, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>{ind + 1}</td>
                                  <td>{val.report_name}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="theme-btn"
                                      onClick={() =>
                                        this.handleCustomReportModelToggle(ind)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className=""
                                        icon={solid("eye")}
                                      />
                                    </button>
                                    <button
                                      type="button"
                                      style={{ marginLeft: "10px" }}
                                      className="theme-btn"
                                      onClick={this.handleCustomReportDelete(
                                        ind
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon={solid("trash-alt")}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="row d-flex1">
                      <div className="col-12">
                        <input
                          className="submitbtncstm"
                          type="submit"
                          value="Save"
                          disabled={this.state.isLoading}
                        />

                        <input
                          onClick={this.handleClose}
                          className="submitbtncstm cancelbtncstm"
                          type="button"
                          value="Cancel"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        {customReportModel && (
          <div className="modalOuter">
            <div
              className="modalBody"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <din>
                <h1>Custom Report</h1>
                <div className="custom-report-container">
                  <div className="select-cus-report">
                    <div>
                      <label>Association Company</label>
                    </div>
                    <select
                      defaultValue={JSON.stringify(customReportSelectedCompany)}
                      onChange={this.handleChangeSelectCompany}
                    >
                      {!isEmpty(companies) &&
                        companies.map((com) => (
                          <option value={JSON.stringify(com)}>
                            {com.title}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="custom-report-s-input">
                    <div>
                      <label htmlFor="s">Report ID</label>
                    </div>
                    <input
                      type="number"
                      placeholder="Report ID"
                      name="associateCustomReportId"
                      value={associateCustomReportId}
                      onChange={this.handleReportChange}
                    />
                  </div>
                  <div className="cus-add-btn">
                    <button type="button" onClick={this.handleAddReports}>
                      <FontAwesomeIcon className="" icon={solid("plus")} />
                    </button>
                  </div>
                </div>
                {!isEmpty(associateCustomReportIdDuplicate) && (
                  <p className="color-red">
                    {associateCustomReportIdDuplicate}
                  </p>
                )}
                <div>
                  <button
                    onClick={() => this.handleCustomReportModelToggle(null)}
                  >
                    Back
                  </button>
                </div>
              </din>
            </div>
          </div>
        )}
        {customReportModel && (
          <div
            className="modalOuter"
            // onClick={() => this.handleCustomReportModelToggle(null)}
          >
            <div
              className="modalBody"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <din>
                <h1>Custom Report</h1>
                <div className="custom-report-container">
                  <div className="select-cus-report">
                    <div>
                      <label>Association Company</label>
                    </div>
                    <select
                      defaultValue={JSON.stringify(customReportSelectedCompany)}
                      onChange={this.handleChangeSelectCompany}
                    >
                      {!isEmpty(companies) &&
                        companies.map((com) => (
                          <option value={JSON.stringify(com)}>
                            {com.title}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="custom-report-s-input">
                    <div>
                      <label htmlFor="s">Report ID</label>
                    </div>
                    <input
                      type="number"
                      placeholder="Report ID"
                      name="associateCustomReportId"
                      value={associateCustomReportId}
                      onChange={this.handleReportChange}
                    />
                  </div>
                  <div className="cus-add-btn">
                    <button type="button" onClick={this.handleAddReports}>
                      <FontAwesomeIcon className="" icon={solid("plus")} />
                    </button>
                  </div>
                </div>
                {!isEmpty(associateCustomReportIdDuplicate) && (
                  <p className="color-red">
                    {associateCustomReportIdDuplicate}
                  </p>
                )}
                <div className="cus-reportId-table">
                  <div className="col-12 mbcstm asscmp-a-table-scroll">
                    <table className="table tablecustompop">
                      <thead>
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col">Company Name</th>
                          <th scope="col">Report ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(
                          customReportNamesData[customReportOpenIndex].reports
                        ) &&
                          customReportNamesData[
                            customReportOpenIndex
                          ].reports.map((val, ind) => {
                            return (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{val.title}</td>
                                <td>{val.report_id}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => this.handleCustomReportModelToggle(null)}
                  >
                    Back
                  </button>
                </div>
              </din>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AddCompanyModel;
