import React, { useState, useEffect } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IntegrationEventTable = ({
  hiredResults,
  firedResults,
  errdServices,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hfEventId, setHfEventId] = useState(null);
  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const  hfEventIdFromUrl = urlParts[urlParts.length - 1];
     setHfEventId(hfEventIdFromUrl);
    }, []);


  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/emp/single/integration/event/status/${hfEventId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const responseData = await response.json();
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hfEventId]);

  // Calculate success count
  const hiredTrueFiredFalse = true; // Placeholder value, replace with actual logic
  // let successCount = 0;
//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const handleDownloadPDF = async () => {
//     try {
//       setPdfLoading(true);
//       const pdfElement = document.getElementById("integration-event-pdf");
//       const pdfBlob = await pdfElementToBlob(pdfElement);
//       const url = URL.createObjectURL(pdfBlob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "integration_event.pdf";
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     } finally {
//       setPdfLoading(false);
//     }
//   };

//   const pdfElementToBlob = async (element) => {
//     const canvas = document.createElement("canvas");
//     const { width, height } = element.getBoundingClientRect();
//     canvas.width = width;
//     canvas.height = height;
//     const ctx = canvas.getContext("2d");
//     const renderCtx = canvas.getContext("2d");
//     const renderCanvas = document.createElement("canvas");
//     renderCanvas.width = width * window.devicePixelRatio;
//     renderCanvas.height = height * window.devicePixelRatio;
//     renderCtx.scale(window.devicePixelRatio, window.devicePixelRatio);
//     renderCtx.fillStyle = "white";
//     renderCtx.fillRect(0, 0, renderCanvas.width, renderCanvas.height);
//     renderCtx.drawImage(element, 0, 0);
//     return new Promise((resolve, reject) => {
//       renderCanvas.toBlob(
//         (blob) => {
//           if (!blob) {
//             reject(new Error("Failed to generate PDF"));
//           }
//           resolve(blob);
//         },
//         "application/pdf",
//         1
//       );
//     });
//   };
  return (
    <div className="emp-installation-controller">
      <div className="emp-installation-sub-controller">
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <img
            style={{ width: "100%", maxWidth: "150px" }}
            src="https://storage.googleapis.com/onepoint-static/OnePoint-Logo-2018-blue.png"
            alt="OnePoint Logo"
          />
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : data ? (
          <>
            <p>
              OnePoint User Provisioning has processed the{" "}
              {hiredTrueFiredFalse ? "hiring" : "firing"} of employee{" "}
              <b>
                {data.first_name} {data.last_name}
              </b>
              .
            </p>
            {data.success > 0 && (
              <p>
                {hiredTrueFiredFalse
                  ? "Below are the status and description of employees for all services."
                  : "Below are the services that the employee was successfully deactivated from."}
              </p>
            )}
            {data.success > 0 && (
              <table  className="emp-installation-status-table">
                <thead>
                  <tr>
                    <th
                      style={{ fontWeight: "800", border: "1px solid black" }}
                    >
                      Service
                    </th>
                    <th
                      style={{ fontWeight: "800", border: "1px solid black" }}
                    >
                      Status
                    </th>
                    <th
                      style={{ fontWeight: "800", border: "1px solid black" }}
                    >
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.integration_event_status.map((event, index) => (
                    <tr key={index}>
                      <td>{event.Name}</td>
                      <td>{event.Status}</td>
                      <td>{event.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div style={{ padding: "40px 0 0 0", fontSize: "10px" }}>
              this is from{" "}
              <a href="https://connect.onehcm.com/products/user-provisioning">
                OnePoint Connect
              </a>
              .
            </div>
          </>
        ) : (
          <p>Error: Failed to load data</p>
        )}
      </div>
    </div>
  );
};

export default IntegrationEventTable;
