import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { integrationDisable } from "../../../constants/constant";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import { renderImage } from "../../../utils/utils";
import Swal from "sweetalert2";

const ProductIntegrationCard = (props) => {
  const {
    image_name,
    integration_name,
    description,
    authenticated,
    settings,
    history,
    url,
    userAdminPermission,
  } = props;

  const integrationCheck = !integrationDisable.includes(url);
  const [editing, setEditing] = useState(false);
  const [cardData, setCardData] = useState({
    integration_name,
    description,
    image_name,
    oldImage: image_name,
    imageFile: null,
  });

  const [saveCard, loadingCard] = useFetch();
  const handleGoIntoIntegration = async () => {
    if (integrationCheck) return;
    if (!editing) history.push(`/app/integrations/${url}`);
  };

  const handleEditAble = (event) => {
    event.stopPropagation();
    setEditing(true);
  };

  const handleSave = async (event) => {
    event.stopPropagation();

    var formData = new FormData();
    formData.append("content_type", url);
    formData.append("header", cardData.integration_name);
    formData.append("contents", cardData.description);
    formData.append("logo", cardData.imageFile);

    const payload = {
      endPoint: "/api/comp/customize/content",
      method: "POST",
      body: formData,
      formData: true,
    };

    await saveCard(payload);
    // dispatch(integrationSaveData({ ...cardData, url: url }));
    setEditing(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCardData({ ...cardData, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const selectableMaxFileSize = 1024 * 1024 * 0.5; // 500KB
    const imageIsPNG = file.type.includes("png");

    if (selectableMaxFileSize > file.size && imageIsPNG) {
      let url = URL.createObjectURL(file);
      setCardData({
        ...cardData,
        imageFile: file,
        image_name: url,
      });
    } else if (!imageIsPNG) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can upload only png image!",
        confirmButtonColor: "#000",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Image size should be less than 500KB!",
      });
    }
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '......' : text;
  };


  return (
    <div
      className="product-card-outer-out productcardout-list"
      onClick={handleGoIntoIntegration}
    >
      <div className="product-card-outer">
        {userAdminPermission && (
          <div className="edit-btn-container">
            {editing ? (
              <>
                <button className="mt-1 ms-1" onClick={handleSave}>
                  Save
                </button>
                <button className="mt-1 ms-1">
                  <label className="cursor-pointer" htmlFor="camera-1">
                    <FontAwesomeIcon icon={solid("camera")} />
                  </label>
                  <input
                    type="file"
                    id="camera-1"
                    hidden
                    onChange={handleImageChange}
                  />
                </button>
              </>
            ) : (
              <span className="edit-icon-btn" onClick={handleEditAble}>
                <FontAwesomeIcon icon={solid("pen-to-square")} />
              </span>
            )}
          </div>
        )}
        {loadingCard ? (
          <Loading />
        ) : (
          <>
            <div className="product-card-inner">
              <img src={renderImage(cardData.image_name)} alt="" />
            </div>
            <div className="product-card-inner1">
              <div className="relative">
                <input
                  type="text"
                  className={`input-h3 ${editing && "active z-index-99"}`}
                  onChange={handleChange}
                  name="integration_name"
                  value={cardData.integration_name}
                  disabled={!editing}
                />
                <div
                  className="prod-description-extra-dev"
                  onClick={handleGoIntoIntegration}
                ></div>
              </div>
              {/* <h3>{integration_name}</h3> */}
              <div className="relative">
                <textarea
                  type="text"
                  className={`input-p ${editing && "active z-index-99"}`}
                  onChange={handleChange}
                  name="description"
                  value={editing ? cardData.description : truncateText(cardData.description, 12)}
                  disabled={!editing}
                />
                <div
                  className="prod-description-extra-dev"
                  onClick={handleGoIntoIntegration}
                ></div>
              </div>

              {/* <p>{description}</p> */}
            </div>
            <div
              className={`product-card-inner2 ${integrationCheck
                  ? "status blue"
                  : authenticated && settings.enabled
                    ? "status green"
                    : "red status"
                }`}
            >
              <h4>
                {integrationCheck
                  ? "COMING SOON"
                  : settings.enabled
                    ? authenticated
                      ? "CONNECTED AND ENABLED"
                      : "ENABLED BUT NOT CONNECTED"
                    : "DISABLED"}
              </h4>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(connect()(ProductIntegrationCard));
