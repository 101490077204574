import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/auth";
// import logo from "../../../assets/images/op-logo.png";
import { isEmpty } from "lodash";
import ColorTheme from "./ColorTheme";
import { themecustomization, thememodalpoup } from "../../../actions/colortheme";

const Navigation = ({
  sidebarHandle,
  sidebarValue,
  sideBar,
  history,
  isAuthenticated,
  dispatch,
  location,
  companyInfo,
  userInfo,
  // userAdminPermission,
  themeModal,
  themeValue,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const dropDownHandle = () => {
    setDropDown((prev) => !prev);
  };

  const handleGoIntoHome = () => {
    const path = location.pathname.includes("/app") ? "/app" : "/ess";
    history.push(path);
  };

  const handleLogOut = () => {
    dispatch(logoutUser());

    // eslint-disable-next-line
    window.location = `/login`;

    localStorage.clear();
  };

  //new
  // const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    // setDropDown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //eed

  //color change end//
  const handlethememodal = () => {
    dispatch(thememodalpoup());
    dispatch(themecustomization(themeValue))
    setDropDown(false);
    // setModalIsOpen(false);
  };
  const userAdminPermission = localStorage.getItem('userAdminPermission')
  return (
    <header style={{top:0}}>
      <div className="logo">
        <div className="logo-wrp">
          <img onClick={handleGoIntoHome}
            // className="header_img"
            style={{ height: '63px' }}
            src={"https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/onepoint_app_logo.png"}
            alt="Logo" />
        </div>
        {!sideBar && (
          <button className="open_sidebar-close" onClick={sidebarHandle}>
            {sidebarValue ? (
              <FontAwesomeIcon icon={solid("x")} />
            ) : (
              <FontAwesomeIcon icon={solid("bars")} />
            )}
          </button>
        )}
      </div>
      <div className="header-right my-flex">
        {isAuthenticated ? (
          <>
            <div className="m-auto">
              <div className="me-2">
                <div className="header-company-info">
                  <p className="color-fff m-0">
                    {!isEmpty(userInfo) && userInfo.username}
                  </p>
                  <p className="color-fff m-0">
                    {!isEmpty(companyInfo) && companyInfo.name}
                  </p>
                </div>
              </div>
            </div>
            <button onClick={dropDownHandle}>
              <img
                className="header_img"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
                alt=""
              />
            </button>
            <ul className={`drop_down-admin ${dropDown && "active"}`}>
              {userAdminPermission === 'true' &&
                <>
                  <li className="hover-active" onClick={handlethememodal} style={{ display: 'flex' }}>
                    <img
                      src="/images/logo/colorIcon.svg"
                      className="theme_img"
                      alt="logout" />
                    Theme Change</li>
                </>}
              <li className="hover-active" onClick={handleLogOut} style={{ display: 'flex' }}>
                <img
                  src="/images/logo/logoutIcon.svg"
                  className="theme_img"
                  alt="logout" />Logout
                {/* <Link to="#">Logout</Link> */}
              </li>
            </ul>
          </>
        ) : (
          <>
            <button onClick={() => history.push("/login")}>Login</button>
          </>
        )}
        {themeModal &&
          <ColorTheme
          />
        }
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const { companyInfo, userInfo } = state.products;
  const { userAdminPermission } = state.dashboard

  return {
    isAuthenticated,
    companyInfo,
    userInfo,
    userAdminPermission,
    themeModal: state.colortheme.thememodal,
  };
};

export default withRouter(connect(mapStateToProps)(Navigation));
