import { CALL_API, Schemas } from "../middleware/api";

export const GET_DIMENTION_WIDTH = "GET_DIMENTION_WIDTH";
export const GET_DIMENTION_MODAL = "GET_DIMENTION_MODAL";
export const WIDTH_VALUE = "WIDTH_VALUE";
export const FONT_SIZE = "FONT_SIZE"
export const FONT_FAMILY = "FONT_FAMILY"
export const LOADER_DYNAMIC_TYPE = "LOADER_DYNAMIC_TYPE"
export const LOADER_DYNAMIC_COLOR = 'LOADER_DYNAMIC_COLOR'
export const THEME_CUSTOMIZATION = 'THEME_CUSTOMIZATION'
export const LOGOUT_MODAL = 'LOGOUT_MODAL'

export const GET_COLOR_THEME_SUCCESS = "GET_COLOR_THEME_SUCCESS";
export const GET_COLOR_THEME_REQUEST = "GET_COLOR_THEME_REQUEST";
export const GET_COLOR_THEME_FAILURE = "GET_COLOR_THEME_FAILURE";


export const themecolorToggle = (data) => {
  return {
    type: GET_DIMENTION_WIDTH,
  };
};

export const themecustomization = (data) => {
  return {
    type: THEME_CUSTOMIZATION,
    payload: data,
  };
};

export const thememodalpoup = (data) => {
  return {
    type: GET_DIMENTION_MODAL,
  };
};

export const dynamicloadercolor = (data) => {
  return {
    type: LOADER_DYNAMIC_COLOR,
    payload: data,
  };
};

export const themewidth = (data) => {
  return {
    type: WIDTH_VALUE,
    payload: data,
  };
};

export const dynamicloader = (data) => {
  return {
    type: LOADER_DYNAMIC_TYPE,
    payload: data,
  };
};

export const dynamifontsize = (data) => {
  return {
    type: FONT_SIZE,
    payload: data,
  };
};

export const dynamifontfamily = (data) => {
  return {
    type: FONT_FAMILY,
    payload: data,
  };
};

export const logoutmodalpopup = (data) => {
  return {
    type: LOGOUT_MODAL,
    payload: data,
  };
};

//API CALL
// const ThemeDataFetchx = () => ({
//   [CALL_API]: {
//     types: [
//       GET_COLOR_THEME_REQUEST,
//       GET_COLOR_THEME_SUCCESS,
//       GET_COLOR_THEME_FAILURE,
//     ],
//     endpoint: `/api/customize/panel`,
//   },
// });

// export const ThemeColorData = () => (dispatch) => {
//   return dispatch(ThemeDataFetchx());
// };

const ThemeDataFetchx = (newAccessControl) => ({
  [CALL_API]: {
    types: [
      GET_COLOR_THEME_REQUEST,
      GET_COLOR_THEME_SUCCESS,
      GET_COLOR_THEME_FAILURE,
    ],
    endpoint: "/api/customize/panel",
    schema: Schemas.PRODUCT_LIST,
    postdata: newAccessControl,
  },
});

export const ThemeColorData =
  (newAccessControl) => (dispatch, getState) => {
    return dispatch(ThemeDataFetchx(newAccessControl));
  };