import React from "react";
import { Link } from "react-router-dom";
import { marketPlaceDetailContent } from "../../constants/constant";
import { isEmpty } from "lodash";

const MarketPlaceDetail = (props) => {
  const {
    match: { params },
  } = props;

  return (
    <div>
      <div className="breadcrumbs">
        <Link to={`/`}>&larr; Back to list of your products</Link>
      </div>
      {!isEmpty(marketPlaceDetailContent[params.name]) ? (
        <div>
          <h1>{marketPlaceDetailContent[params.name].title} Integration</h1>
          <div className="my-flex">
            <div className="one-col-9">
              <div className="p-2">
                <div className="marketplace-description">
                  <h1 className="text-left">
                    {marketPlaceDetailContent[params.name].title} Integration
                  </h1>
                  {marketPlaceDetailContent[params.name].description}
                </div>
              </div>
            </div>
            <div className="one-col-3">
              <div className="p-2">
                <div className="market-image">
                  <img
                    src={`/${marketPlaceDetailContent[params.name].img}`}
                    alt={marketPlaceDetailContent[params.name].img}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Integration not found</h1>
      )}
    </div>
  );
};

export default MarketPlaceDetail;
