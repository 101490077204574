import React, { Component } from "react";

import SelectSetting from "./SelectSetting";

import "../../styles/WebclockAttestation.css";

const explanationFeature = false;

const CheckTextInput = (oldVal, newVal) => {
  const newValNum = Number(newVal);
  if (isNaN(newValNum) || newValNum < 0) {
    return oldVal;
  }
  return newVal;
};

const StepInferredConditions = ({ changeState, thisStep }) => {
  const { inferred_condition: conditions } = thisStep;

  return (
    <div className="inferred">
      <div className="condition">
        <span>
          <input
            type="checkbox"
            checked={
              conditions.total_hours_gte !== null &&
              conditions.total_hours_gte !== undefined
            }
            onChange={(e) =>
              changeState("thisStep", {
                ...thisStep,
                inferred_condition: {
                  ...conditions,
                  total_hours_gte: e.target.checked ? "0" : null,
                },
              })
            }
          />
        </span>
        <span>Total Hours Greater Than Or Equal To...</span>
        {conditions.total_hours_gte !== null &&
          conditions.total_hours_gte !== undefined && (
            <span>
              <input
                type="text"
                value={conditions.total_hours_gte}
                onChange={(e) =>
                  changeState("thisStep", {
                    ...thisStep,
                    inferred_condition: {
                      ...conditions,
                      total_hours_gte: CheckTextInput(
                        conditions.total_hours_gte,
                        e.target.value
                      ),
                    },
                  })
                }
              />
            </span>
          )}
      </div>
      <div className="condition">
        <span>
          <input
            type="checkbox"
            checked={
              conditions.total_hours_lte !== null &&
              conditions.total_hours_lte !== undefined
            }
            onChange={(e) =>
              changeState("thisStep", {
                ...thisStep,
                inferred_condition: {
                  ...conditions,
                  total_hours_lte: e.target.checked ? "0" : null,
                },
              })
            }
          />
        </span>
        <span>Total Hours Less Than Or Equal To...</span>
        {conditions.total_hours_lte !== null &&
          conditions.total_hours_lte !== undefined && (
            <span>
              <input
                type="text"
                value={conditions.total_hours_lte}
                onChange={(e) =>
                  changeState("thisStep", {
                    ...thisStep,
                    inferred_condition: {
                      ...conditions,
                      total_hours_lte: CheckTextInput(
                        conditions.total_hours_lte,
                        e.target.value
                      ),
                    },
                  })
                }
              />
            </span>
          )}
      </div>
      <div className="condition">
        <span>
          <input
            type="checkbox"
            checked={
              conditions.not_take_30 !== null &&
              conditions.not_take_30 !== undefined
            }
            onChange={(e) =>
              changeState("thisStep", {
                ...thisStep,
                inferred_condition: {
                  ...conditions,
                  not_take_30: e.target.checked ? true : null,
                },
              })
            }
          />
        </span>
        <span>Did Not Take 30 Minute Break</span>
      </div>
    </div>
  );
};

const StepSavedValues = ({ changeState, thisStep }) => {
  return (
    <div className="savedVals">
      <div>
        <SelectSetting
          onChange={(e) =>
            changeState("thisStep", {
              ...thisStep,
              saved_value: e.target.value === "yes" ? true : false,
              saved_extra_field_index:
                e.target.value === "yes"
                  ? thisStep.saved_extra_field_index !== null
                    ? thisStep.saved_extra_field_index
                    : 0
                  : null,
            })
          }
          label={"Record Answer in OnePoint:"}
          value={thisStep.saved_value ? "yes" : "no"}
          options={[
            {
              label: "Yes",
              value: "yes",
            },
            {
              label: "No",
              value: "no",
            },
          ]}
        />
      </div>
      {thisStep.saved_value && (
        <div>
          <SelectSetting
            onChange={(e) =>
              changeState("thisStep", {
                ...thisStep,
                saved_extra_field_index: Number(e.target.value),
              })
            }
            label={"Time Entry Extra Field Number:"}
            value={
              thisStep.saved_extra_field_index
                ? String(thisStep.saved_extra_field_index)
                : "0"
            }
            options={[
              {
                label: "1",
                value: "0",
              },
              {
                label: "2",
                value: "1",
              },
              {
                label: "3",
                value: "2",
              },
              {
                label: "4",
                value: "3",
              },
              {
                label: "5",
                value: "4",
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

const StepOutcomes = ({
  changeState,
  thisStep,
  step_ids,
  step_id,
  removeOutcome,
  addOutcome,
  inferredOutcomesOnly,
}) => {
  const { outcomes, type } = thisStep;

  return (
    <div className="outcomes">
      <div className="heading_style">Outcomes</div>
      <div className="cont">
        {outcomes && outcomes.length === 0 && <div>No outcomes added</div>}
        {outcomes &&
          outcomes.length > 0 &&
          outcomes.map((outcome, i) => (
            <div className="outcome" key={`ouhw${i}`}>
              <div className="value">
                {inferredOutcomesOnly ? (
                  <div>
                    <select
                      value={outcome.inferred_result ? "yes" : "no"}
                      onChange={(e) =>
                        changeState("thisStep", {
                          ...thisStep,
                          outcomes: outcomes.map((o, innerI) => {
                            if (innerI === i) {
                              return {
                                ...o,
                                inferred_result:
                                  e.target.value === "yes" ? true : false,
                              };
                            } else {
                              return o;
                            }
                          }),
                        })
                      }
                    >
                      <option value={"yes"}>True</option>
                      <option value={"no"}>False</option>
                    </select>
                  </div>
                ) : (
                  <span
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>Value:</span>
                    <input
                      style={{ flex: "1" }}
                      type="text"
                      value={outcome.value}
                      onChange={(e) =>
                        changeState("thisStep", {
                          ...thisStep,
                          outcomes: outcomes.map((o, innerI) => {
                            if (innerI === i) {
                              return { ...o, value: e.target.value };
                            } else {
                              return o;
                            }
                          }),
                        })
                      }
                    />
                  </span>
                )}
              </div>
              {explanationFeature &&
                (type === "question" || type === "summary") && (
                  <div>
                    <label>Require Explanation:</label>
                    <select
                      value={outcome.require_explanation ? "yes" : "no"}
                      onChange={(e) =>
                        changeState("thisStep", {
                          ...thisStep,
                          outcomes: outcomes.map((o, innerI) => {
                            if (innerI === i) {
                              return {
                                ...o,
                                require_explanation:
                                  e.target.value === "yes" ? true : false,
                              };
                            } else {
                              return o;
                            }
                          }),
                        })
                      }
                    >
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                  </div>
                )}
              <div>
                <span>Next Step:</span>
                <select
                  value={outcome.next_step ? outcome.next_step : "end"}
                  onChange={(e) =>
                    changeState("thisStep", {
                      ...thisStep,
                      outcomes: outcomes.map((o, innerI) => {
                        if (innerI === i) {
                          return {
                            ...o,
                            next_step:
                              e.target.value === "end" ? null : e.target.value,
                          };
                        } else {
                          return o;
                        }
                      }),
                    })
                  }
                >
                  <option value={"end"}>None (finish attestation)</option>
                  {step_ids.map((id, i) => {
                    if (id === step_id) {
                      return null;
                    }
                    return (
                      <option key={`euwn${i}`} value={id}>
                        {`Step ${i + 1}`}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ textAlign: "right" }}>
                <button onClick={() => removeOutcome(i)}>REMOVE OUTCOME</button>
              </div>
            </div>
          ))}
      </div>
      <div style={{ textAlign: "right" }}>
        <button onClick={() => addOutcome()}>ADD OUTCOME</button>
      </div>
    </div>
  );
};

const StepOutline = ({
  index,
  thisStep,
  propStep,
  deleteSelf,
  saveStepChanges,
  children,
  first_step_id,
  step_id,
  selectAsNewFirstStep,
}) => {
  return (
    <div className="step question">
      <div className="stepHeader">
        <span className="stepLabel">Step {index + 1}</span>
        <span className="firstStepButton">
          <span>Starting Step</span>{" "}
          <input
            type="radio"
            checked={first_step_id === step_id}
            onChange={selectAsNewFirstStep}
          />
        </span>
      </div>
      {children}
      {JSON.stringify(propStep) !== JSON.stringify(thisStep) && (
        <div>
          <button onClick={() => saveStepChanges()}>SAVE CHANGES</button>
        </div>
      )}
      <div className="buts">
        <button className="red" onClick={() => deleteSelf()}>
          REMOVE STEP
        </button>
      </div>
    </div>
  );
};

class Step extends Component {
  constructor(props) {
    super(props);
    this.state = { thisStep: { ...props.step } };

    this.saveStepChanges = this.saveStepChanges.bind(this);
    this.addOutcome = this.addOutcome.bind(this);
    this.removeOutcome = this.removeOutcome.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  changeState(key, newVal) {
    this.setState({ [key]: newVal });
  }

  saveStepChanges() {
    const { onChange } = this.props;
    const { thisStep } = this.state;
    onChange(thisStep);
  }

  addOutcome() {
    const { thisStep } = this.state;
    const { outcomes } = thisStep;
    const newOutcomes = [
      ...outcomes,
      { inferred_result: false, next_step: null, value: "" },
    ];
    this.setState({ thisStep: { ...thisStep, outcomes: newOutcomes } });
  }

  removeOutcome(oIndex) {
    const { thisStep } = this.state;
    let { outcomes } = thisStep;

    const newOutcomes = outcomes.filter((sid, i) => i !== oIndex);

    this.setState({ thisStep: { ...thisStep, outcomes: newOutcomes } });
  }

  render() {
    const {
      index,
      deleteSelf,
      step: propStep,
      step_ids,
      step_id,
      first_step_id,
      selectAsNewFirstStep,
    } = this.props;
    const { thisStep } = this.state;
    const { type } = thisStep;

    if (type === "question") {
      return (
        <StepOutline
          step_id={step_id}
          selectAsNewFirstStep={selectAsNewFirstStep}
          first_step_id={first_step_id}
          index={index}
          thisStep={thisStep}
          propStep={propStep}
          deleteSelf={() => deleteSelf(step_id)}
          saveStepChanges={this.saveStepChanges}
        >
          <div>Type: Question</div>
          <div className="">
            <div>Question Text</div>
            <div>
              <textarea
                type="text"
                value={thisStep.question_text}
                onChange={(e) =>
                  this.setState({
                    thisStep: { ...thisStep, question_text: e.target.value },
                  })
                }
              />
            </div>
          </div>
          <StepOutcomes
            addOutcome={this.addOutcome}
            removeOutcome={this.removeOutcome}
            step_ids={step_ids}
            step_id={step_id}
            thisStep={thisStep}
            changeState={(k, v) => this.changeState(k, v)}
          />
          <StepSavedValues
            thisStep={thisStep}
            changeState={(k, v) => this.changeState(k, v)}
          />
        </StepOutline>
      );
    }

    if (type === "inferred_split") {
      return (
        <StepOutline
          step_id={step_id}
          selectAsNewFirstStep={selectAsNewFirstStep}
          first_step_id={first_step_id}
          index={index}
          thisStep={thisStep}
          propStep={propStep}
          deleteSelf={() => deleteSelf(step_id)}
          saveStepChanges={this.saveStepChanges}
        >
          <div>Type: Split</div>
          <div className="">
            <div>Split Conditions (Select multiple for AND)</div>
            <StepInferredConditions
              thisStep={thisStep}
              changeState={(k, v) => this.changeState(k, v)}
            />
          </div>
          <StepOutcomes
            inferredOutcomesOnly={true}
            addOutcome={this.addOutcome}
            removeOutcome={this.removeOutcome}
            step_ids={step_ids}
            step_id={step_id}
            thisStep={thisStep}
            changeState={(k, v) => this.changeState(k, v)}
          />
        </StepOutline>
      );
    }

    if (type === "summary") {
      return (
        <StepOutline
          step_id={step_id}
          selectAsNewFirstStep={selectAsNewFirstStep}
          first_step_id={first_step_id}
          index={index}
          thisStep={thisStep}
          propStep={propStep}
          deleteSelf={() => deleteSelf(step_id)}
          saveStepChanges={this.saveStepChanges}
        >
          <div className="heading_style">Type: Daily Summary</div>
          <div className="">
            <div className="heading_style">Summary Text</div>
            <div>
              <textarea
                type="text"
                className="paragraph_style"
                value={thisStep.question_text}
                onChange={(e) =>
                  this.setState({
                    thisStep: { ...thisStep, question_text: e.target.value },
                  })
                }
              />
            </div>
          </div>
          <StepOutcomes
            addOutcome={this.addOutcome}
            removeOutcome={this.removeOutcome}
            step_ids={step_ids}
            step_id={step_id}
            thisStep={thisStep}
            changeState={(k, v) => this.changeState(k, v)}
          />
          <StepSavedValues
            thisStep={thisStep}
            changeState={(k, v) => this.changeState(k, v)}
          />
        </StepOutline>
      );
    }

    return null;
  }
}

class WebclockAttestation extends Component {
  constructor(props) {
    super(props);
    this.state = { add_new: null };

    this.addNewStep = this.addNewStep.bind(this);
    this.deleteStep = this.deleteStep.bind(this);
  }

  addNewStep(addNew) {
    const { value, onChange } = this.props;
    const { steps, step_ids, last_step_id, first_step_id } = value;
    const newLastID = last_step_id + 1;

    let newStep = {
      type: addNew.type,
      outcomes: [],
      saved_value: false,
      saved_extra_field_index: null,
    };

    switch (addNew.type) {
      case "question":
        newStep.question_text = "";
        break;
      case "summary":
        newStep.question_text = "";
        break;
      case "inferred_split":
        newStep.inferred_condition = {
          total_hours_gte: null,
          total_hours_lte: null,
          not_take_30: null,
        };
        break;
      default:
        break;
    }

    let newFirstID = first_step_id;
    if (step_ids) {
      if (step_ids.length === 0) {
        newFirstID = String(newLastID);
      }
    } else {
      newFirstID = String(newLastID);
    }

    const newSteps = { ...steps, [newLastID]: newStep };
    const newStepIDs = [...step_ids, String(newLastID)];

    onChange({
      ...value,
      first_step_id: newFirstID,
      steps: newSteps,
      step_ids: newStepIDs,
      last_step_id: newLastID,
    });
  }

  deleteStep(id) {
    const { value, onChange } = this.props;
    const { steps, step_ids, first_step_id } = value;

    let newSteps = { ...steps };
    delete newSteps[id];
    const newStepIDs = step_ids.filter((sid) => sid !== id);

    let newFirstID = first_step_id;

    if (first_step_id === id) {
      if (newStepIDs && newStepIDs.length > 0) {
        newFirstID = newStepIDs[0];
      } else {
        newFirstID = null;
      }
    }

    // change all outcomes that point to this step to null
    newStepIDs.forEach((id) => {
      const newOutcomes = newSteps[id].outcomes.map((o) => {
        if (o.next_step && o.next_step === id) {
          o.next_step = null;
        }
        return o;
      });
      newSteps[id].outcomes = newOutcomes;
    });

    onChange({
      ...value,
      steps: newSteps,
      step_ids: newStepIDs,
      first_step_id: newFirstID,
    });
  }

  render() {
    const { add_new } = this.state;
    const { value, onChange } = this.props;
    const { active, steps, step_ids, first_step_id } = value;

    return (
      <div className="WebclockAttestation">
        <div>
          <SelectSetting
            onChange={(e) =>
              onChange({
                ...value,
                active: e.target.value === "yes" ? true : false,
              })
            }
            label={"Attestation On/Off:"}
            value={active ? "yes" : "no"}
            options={[
              {
                label: "On",
                value: "yes",
              },
              {
                label: "Off",
                value: "no",
              },
            ]}
          />
        </div>
        {active && (
          <div>
            <div>
              <div style={{ fontWeight: "600", textDecoration: "underline" }} className="font_color">
                Steps
              </div>
            </div>
            <div className="steps">
              {step_ids && step_ids.length === 0 && <div>No Steps</div>}
              {step_ids.map((step_id, i) => (
                <Step
                  first_step_id={first_step_id}
                  selectAsNewFirstStep={() =>
                    onChange({ ...value, first_step_id: step_id })
                  }
                  step_ids={step_ids}
                  step={steps[step_id]}
                  deleteSelf={() => this.deleteStep(step_id)}
                  key={`ewd${step_id}`}
                  index={i}
                  step_id={step_id}
                  onChange={(newStep) =>
                    onChange({
                      ...value,
                      steps: { ...steps, [step_id]: newStep },
                    })
                  }
                />
              ))}
            </div>
            <div className="buts">
              {add_new ? (
                <div className="add">
                  <div>Add New Step</div>
                  <SelectSetting
                    onChange={(e) =>
                      this.setState({ add_new: { type: e.target.value } })
                    }
                    label={"Type:"}
                    value={add_new.type}
                    options={[
                      {
                        label: "Question",
                        value: "question",
                      },
                      {
                        label: "Split",
                        value: "inferred_split",
                      },
                      {
                        label: "Summary",
                        value: "summary",
                      },
                    ]}
                  />
                  <button onClick={() => this.addNewStep(add_new)}>ADD</button>
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.setState({ add_new: null })}
                  >
                    CANCEL
                  </button>
                </div>
              ) : (
                <button
                  onClick={() =>
                    this.setState({ add_new: { type: "question" } })
                  }
                >
                  ADD STEP
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WebclockAttestation;
