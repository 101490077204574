import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const MarketPlaceCard = ({
  integration_name,
  image_name,
  description,
  url,
  history,
  location,
  ThemeFontstyle,
}) => {
  const handleGoIntoIntegration = async () => {
    const conditions = location.pathname.includes("/ess");
    if (conditions) {
      history.push(`/ess/integrations/${url}`);
    } else {
      history.push(`/app/integrations/${url}`);
    }
  };
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '......' : text;
  };
  return (
    <div className="market-place-card-outer">
      <div className="market-place-card" onClick={handleGoIntoIntegration}>
        <div className="market-logo">
          <img src={image_name} alt={integration_name} />
        </div>
        <h2 className="mb-1"
          style={{ fontStyle: `${ThemeFontstyle.fontsizestyle}`, textDecoration: ThemeFontstyle.fontsizestyle === 'underline' ? 'underline' : 'none' }}
        >{integration_name}</h2>
        <div className="description">
          <p className="m-0">{truncateText(description, 7)}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ThemeFontstyle: state.colortheme };
};

export default withRouter(connect(mapStateToProps)(MarketPlaceCard));
// export default withRouter(MarketPlaceCard);
