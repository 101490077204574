import React, { useState } from "react";
import "../../css/employeeStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { productSettingsToggle } from "../../../actions/dashboard";


const sideBarData = [
  // { title: "Dashboard", to: "/dashboard" },
  { title: "Product", to: "/app" },
  // { title: "Settings", to: "/app/settings" },
];

const SideBar = ({ history, location, userInfo, dispatch, userAdminPermission }) => {

  const sideBarDataAdmin = [
    // { title: "Dashboard", to: "/dashboard" },
    { title: "Product", to: "/app" },
    {
      title: "Company",
      open: (location.pathname === "/app" || location.pathname === "/dashboard") ? false : true,
      dropDown: [
        {
          title: "Add company",
          to: "/app/add-company",
        },
        {
          title: "Company Info",
          to: "/app/status-company",
        },
      ],
    },
    { title: "Settings", to: "/app/settings" },
  ];
  const sideBarDataAdminPer = [
    // { title: "Dashboard", to: "/dashboard" },
    { title: "Product", to: "/app" },
    {
      title: "Company",
      open: (location.pathname === "/app" || location.pathname === "/dashboard") ? false : true,
      dropDown: [
        {
          title: "Add company",
          to: "/app/add-company",
        },
      ],
    },
    { title: "Settings", to: "/app/settings" },
  ];
  const userAdminPermissionVal = localStorage.getItem('userAdminPermission');
  const userPermisssion = userAdminPermissionVal === 'true' ? sideBarDataAdmin : sideBarDataAdminPer
  const [sideBarState, setSideBarData] = useState(sideBarData);
  const [sideBarAdminState, setSideBarAdminData] = useState(userPermisssion);

  const handleGotoPage = (to) => () => {
    if (to === "/app/settings") {
      dispatch(productSettingsToggle());
    } else {
      history.push(to);
    }
  };

  const handleOpenDropDown = (index) => () => {
    if (userInfo && userInfo.is_system_admin) {
      setSideBarAdminData((prev) => {
        const copy = [...prev];
        copy[index].open = !copy[index].open;
        return copy;
      });
    } else {
      setSideBarData((prev) => {
        const copy = [...prev];
        copy[index].open = !copy[index].open;
        return copy;
      });
    }
  };

  // const dropDownOpenCheck = (down) => {
  //   const index = down.findIndex(({ to }) => to === location.pathname);
  //   return -1 < index ? true : false;
  // };

  return (
    <div className="emp-side-bar">
      <ul className="dash-menu">
        {console.log('userInfo.is_system_admin', userInfo && userInfo.is_system_admin)}
        {(userInfo && userInfo.is_system_admin
          ? sideBarAdminState
          : sideBarState
        ).map((item, index) => {
          const isActive = item.to === location.pathname || `/app/system-product/biz_library` === location.pathname ? "active" : ''
          if (item.to) {
            return (
              <li key={index}>
                <span
                  // className={item.to === location.pathname ? "active" : ""}
                  className={item.to === '/app/settings' ? "" : isActive}
                  onClick={handleGotoPage(item.to)}
                >
                  <FontAwesomeIcon icon={solid("chart-column")} />
                  <span>{item.title}</span>
                </span>
              </li>
            );
          } else {
            return (
              <li key={index}>
                <span
                  onClick={handleOpenDropDown(index)}
                  className={item.to === location.pathname ? "active" : ""}
                >
                  <div className="my-flex my-justify-content-between">
                    <span>
                      <FontAwesomeIcon icon={regular("building")} />
                      {item.title}
                    </span>
                    <span>
                      {item.open ? (
                        <FontAwesomeIcon icon={solid("arrow-up")} />
                      ) : (
                        <FontAwesomeIcon icon={solid("arrow-down")} />
                      )}
                    </span>
                  </div>
                </span>
                {item.open ? (
                  <ul className="drop-down">
                    {item.dropDown.map((down, i) => {
                      return (
                        <li key={i} onClick={handleGotoPage(down.to)}>
                          <span
                            className={
                              (down.to === location.pathname ||
                                (location.pathname === '/app/list-company' && down.to === '/app/status-company'))
                                ? "active" : ""
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              icon={solid("chart-column")}
                            />
                            <span>{down.title}</span>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo, isFetching: productsAreFetching } = state.products;
  const { userAdminPermission } = state.dashboard
  return { userInfo, productsAreFetching, userAdminPermission };
};

export default withRouter(connect(mapStateToProps)(SideBar));
