import React, { useEffect, useState } from 'react'
import HideShow from '../../../components/ui/HideShow'
import CustomProductModal from '../UI/CustomProductModal'
import { getToken } from '../../../actions/auth';
import axios from 'axios';
import Loading from '../../../components/ui/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFetch from '../../hooks/useFetch';
import ReactPaginate from 'react-paginate';
import toast, { Toaster } from 'react-hot-toast';

export default function SyncTrainingHistoryIntegration() {
    const [openPopup, setOpenPopup] = useState(false)
    const [checkBox, setCheckBox] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [responeView, setResponeView] = useState([])
    const [style, setStyle] = useState("light");
    const [fetchData] = useFetch();
    const [trainingHistory, setTrainingHistory] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [syncLoader, setSyncLoader] = useState(false)
    const [searchDate, setSearchDate] = useState('')
    const [sortOrder, setSortOrder] = useState('asc');
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [enabledisableValue, setEnabledisableValue] = useState(1)
    const openModal = () => {
        setOpenPopup(true)
    }
    const close = () => {
        setOpenPopup(false)
    }

    const handleSort = (column) => {
        const sortedHistory = [...trainingHistory];
        sortedHistory.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });
        setTrainingHistory(sortedHistory);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleCheck = (e) => {
        setCheckBox(e.target.checked)
        setSelectedDate('')
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setResponeView([])
    }
    const dateValue = (e) => {
        setSelectedDate(e.target.value)
    }

    const ViewModal = async (event, type) => {
        setShowModal(type === 0 ? true : false)
        setSyncLoader(type === 0 ? false : true)
        event.stopPropagation();
        try {
            const { token, userToken } = getToken();
            var formData = {
                date: selectedDate,
                tp: type
            }
            const data = { tp: type }
            const response = await axios.post(
                "/api/sync/training/history",
                type === 0 ? formData : data,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setResponeView(response.data)
            if (response.data.Id) {
                try {
                    const data = {
                        Id: response.data.Id
                    }
                    const res = await axios.post(
                        "/api/import/training/history",
                        data,
                        {
                            headers: {
                                Opusertoken: userToken,
                                Authorization: token,
                            },
                        }
                    )
                    console.log('Response:-', res);
                    setResponeView([])
                    setSyncLoader(false)
                } catch (error) {
                    console.log('IdError:-', error);
                }

            }
        } catch (error) {
            console.log('Error:-', error);
        }
        setRefresh(!refresh)
    }

    const changeStyle = async () => {
        try {
            const { token, userToken } = getToken();
            const formData = {
                integration_id: 18,
                status: Number(enabledisableValue)
            }
            await axios.post(
                "/api/company/integration/job/status",
                formData,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setStyle("light");
            toast.success("Update Successfull !");
        }
        catch (error) {
            console.log('Error:-', error);
        }
    };

    useEffect(() => {
        GetDescription()
    }, [refresh])

    const GetDescription = async () => {
        try {
            const payload = {
                endPoint: searchDate ? `/api/sync/training/history/${searchDate}` : `/api/sync/training/history`,
                method: "get",
            };
            const response = await fetchData(payload);
            setTrainingHistory(response)
        } catch (error) {
            console.log("error", error);
        }
        setSyncLoader(false)
    };

    const handleSearchDate = (e) => {
        setSearchDate(e.target.value)
        setRefresh(!refresh)
        setSyncLoader(true)
    }

    const handleChange = (e) => {
        const name = e.target.value
        if (name === '0') {
            setStyle("dark")
        }
        else {
            setStyle("dark")
        }
        setEnabledisableValue(name)
    }

    const rowPerPageValue = [10, 20, 30, 50, 100, 200];
    const offset = currentPage * rowPerPage
    const handleSelectSort = (e) => {
        const { value } = e.target;
        setCurrentPage(0);
        setRowPerPage(Number(value));
    };
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }
    const pageCount = Math.ceil(
        Array.isArray(trainingHistory)
            ? trainingHistory.length / rowPerPage
            : 0 / rowPerPage
    );
    const handlerefresh = () => {
        setStyle("dark");
    }
    return (
        <>
            <div className="product-setting-inner email-notification mb-2">
                <HideShow
                    label={<label>Sync Training History ON/OFF</label>}
                    body={<div>
                        <div className="product-setting-inner2 integration-btn">
                            <button onClick={openModal}>
                                Sync History
                            </button>
                            <div>

                                <select
                                    onChange={handleChange}
                                    name="enabled"
                                    className='sync_enb'
                                    disabled={style === 'light' ? true : false}
                                >
                                    <option value={1}>ENABLED</option>
                                    <option value={0}>DISABLED</option>
                                </select>
                            </div>
                            {style === 'light' ? <FontAwesomeIcon onClick={handlerefresh} icon={solid("arrows-rotate")} className={style} /> :
                                <FontAwesomeIcon onClick={changeStyle} icon={solid("floppy-disk")} className={style} />}
                        </div>
                    </div>}
                />
            </div>
            {openPopup &&
                <CustomProductModal
                    heading="Sync Training History"
                    closeModal={close}>
                    <>
                        <div className="product-company-detail tabmar sync_trainig">
                            <div className="company-details-container">
                                <div className="button-container D_flex">
                                    <div className="checkbox-container sync_history_mar">
                                        <input type="checkbox" id="view-data"
                                            onChange={handleCheck} name="view-data" />
                                        <label htmlFor="view-data">View Data</label>
                                    </div>
                                    <div className="calendar-picker-container sync_history_mar">
                                        <label htmlFor="start-date">Date</label>
                                        <input type="date" id="start-date" name="start-date" onChange={dateValue} disabled={!checkBox} />
                                    </div>
                                    <button className="btn btn-primary sync_history_mar d_width" onClick={(e) => { ViewModal(e, 0) }}>
                                        View
                                    </button>
                                    <button onClick={(e) => { ViewModal(e, 1) }} className="btn btn-secondary d_width">
                                        Sync
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="product-company-detail">
                            <div className='cus_sync'>
                                <label htmlFor="" className="product-company-detail-heading">
                                    Sync History
                                </label>

                                <div className="row_filter">
                                    <label htmlFor="rows">
                                        <b>Rows On Page:</b>
                                    </label>
                                    <select
                                        id="rows"
                                        onChange={handleSelectSort}
                                        defaultValue={rowPerPage}
                                    >
                                        {rowPerPageValue.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="calendar-picker-container">
                                    <label htmlFor="start-date" className='date_search'>Search</label>
                                    <input type="date" id="start-date" name="start-date"
                                        onChange={(e) => { handleSearchDate(e) }}
                                        className='date_search_inp' />
                                </div>
                            </div>
                            {syncLoader ? <Loading /> :
                                <div className="company_system_table">
                                    <div className="tbl_container tabmar tabmar_tab">
                                        {trainingHistory.length === 0 ? (
                                            <div className='tab_not_found'>
                                                Data Not Found...
                                            </div>
                                        ) : (
                                            <table>
                                                <thead className="sync_tab_position">
                                                    <tr>
                                                        <th className="tbl-td-th">Sr.No</th>
                                                        <th className="tbl-td-th"> Name
                                                            <button onClick={() => handleSort('name')}>
                                                                {sortOrder === 'asc' ? '↑' : '↓'}
                                                            </button>
                                                        </th>
                                                        <th className="tbl-td-th"> Status
                                                            <button onClick={() => handleSort('status')}>
                                                                {sortOrder === 'asc' ? '↑' : '↓'}
                                                            </button>
                                                        </th>
                                                        <th className="tbl-td-th"> Date and Time
                                                            <button onClick={() => handleSort('created_at')}>
                                                                {sortOrder === 'asc' ? '↑' : '↓'}
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {trainingHistory.slice(offset, offset + rowPerPage).map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="tbl-td-th">{index + 1}</td>
                                                            <td className="tbl-td-th">{item.name}</td>
                                                            <td className={`tbl-td-th ${item.status === 0 ? 'pending' : item.status === 1
                                                                ? 'success' : item.status === 2 ? 'fail' : ''}`}
                                                            >
                                                                {item.status === 0 ? 'Pending' : item.status === 1
                                                                    ? 'Success' : item.status === 2 ? 'Fail' : ''}
                                                            </td>
                                                            <td className="tbl-td-th">{item.created_at}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            onPageChange={handlePageClick}
                                            pageCount={pageCount}
                                            previousLabel="Previous"
                                            renderOnZeroPageCount={null}
                                            containerClassName={"pagination-container"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"disabled"}
                                            activeClassName={"active"}
                                            pageRangeDisplayed={2}
                                            marginPagesDisplayed={1}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                    {showModal && (
                        <div className='modal_open_cus'>
                            <div className='modal_open_cus_body_popup'>
                                <div className='cus_mod_popup'>
                                    <h2>View Details</h2>
                                    <button onClick={handleCloseModal} >Close</button>
                                </div>
                                {responeView.length === 0 ?
                                    <Loading /> : <div className="company_system_table">
                                        <div className="tbl_containers">
                                            {responeView.totalResults === 0 ? (
                                                <div className='tab_not_found'>
                                                    Data Not Found...
                                                </div>
                                            ) : (
                                                <table>
                                                    <thead className='tab_head'>
                                                        <tr>
                                                            <th className="tbl-td-th">User Name</th>
                                                            <th className="tbl-td-th">Display Name</th>
                                                            <th className="tbl-td-th">Activity Type</th>
                                                            <th className="tbl-td-th">Activity Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {responeView && responeView.Resources &&
                                                            responeView.Resources.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="tbl-td-th">{item.userName}</td>
                                                                        <td className="tbl-td-th">{item.displayName}</td>
                                                                        <td className="tbl-td-th">{item.activityType}</td>
                                                                        <td className="tbl-td-th">{item.activityName}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>)}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    )}

                </CustomProductModal>
            }
            <Toaster />
        </>
    )
}