import React, { useState } from 'react';
import '../../styles/HideShow.css';

const ThemeHideShow = ({ label, body }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(prevShow => !prevShow);
  };

  return (
    <div className="HideShow">
      <div className="top" onClick={toggleShow}>
        <span>{label}</span>
        <span className="controllers">
          {show ? '-' : '+'}
        </span>
      </div>
      {show && (
        <div className="bot">
          {body}
        </div>
      )}
    </div>
  );
};

export default ThemeHideShow;
