import React, { useEffect, useState } from "react";
import ProductModal from "../UI/ProductModal";
import { connect } from "react-redux";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import { productsAvailable } from "../../../constants/constant";
import ToggleButton from "react-toggle-button";
import { getToken } from "../../../actions/auth";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const CompanyDetailsModal = (props) => {
  const { handleActiveKey, activeKey } = props;
  const [fetchProductByKey, fetchLoading] = useFetch();
  const [productData, setProductData] = useState(null);

  const productsAvailableCopy = { ...productsAvailable };
  const handleFetchProduct = async () => {
    const payload = {
      method: "get",
      endPoint: `/api/products`,
      headers: { Authorization: activeKey },
    };

    const res = await fetchProductByKey(payload);
    setProductData(res);
  };

  useEffect(() => {
    handleFetchProduct();
  }, []);

  // ToggleActive start

  const companyProductAddandRemove = async (value, url) => {
    try {
      const { token, userToken } = getToken();
      var formData = {
        company_id: productData && (productData.user.company_id),
        product_url: value === 'Inactive' ? url : url.product_url,
        change_type: value === 'Inactive' ? 'ADD' : 'DELETE'
      }
      const response = await axios.post(
        "/api/companyproductstatus/update",
        formData,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      toast.success("Updated Successfully!")
      console.log("Response:", response.data);
    } catch (error) {
      toast.error("Invalid request error")
      console.log("Error:", error);
    }
    handleFetchProduct();
  }
  return (
    <div>
      <ProductModal
        heading="Company Products"
        closeModal={handleActiveKey(null)}
      >
        {fetchLoading ? (
          <Loading />
        ) : (
          <>
            <div className="product-company-detail tabmar">
              <div className="company-details-container">
                <p className="m-0">
                  <strong>Company Name:</strong>
                  <strong className="ms-2 color-green">
                    {productData && productData.company.name}
                  </strong>
                </p>
                <p className="mb-0 mt-0 ms-2">
                  <strong>Company Short Name:</strong>
                  <strong className="ms-2 color-green">
                    {productData && productData.company.short_name}
                  </strong>
                </p>
              </div>
            </div>
            <div className="product-company-detail">
                <label htmlFor="" className="product-company-detail-heading">
                  Products</label>
              <div className="company_system_table">
                <div className="tbl_container tabmar">
                  <table>
                    <thead>
                      <tr>
                        <th className="tbl-td-th">Product</th>
                        <th className="tbl-td-th">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productData &&
                        Array.isArray(productData.products) &&
                        productData.products.map((item, index) => {
                          delete productsAvailableCopy[item.product_url];
                          return (
                            <tr key={index}>
                              <th className="tbl-td-th">{item.product_name}</th>
                              <th className="tbl-td-th">
                                <ToggleButton
                                  inactiveLabel={"Inactive"}
                                  activeLabel={"Active"}
                                  value={item.product_name}
                                  onToggle={(value) => {
                                    companyProductAddandRemove(
                                      value,
                                      item
                                    );
                                  }}
                                />
                              </th>
                              {/* <th>
                                      <button className="me-10-px">
                                        <FontAwesomeIcon icon={solid("eye")} />
                                      </button>
                                    </th> */}
                            </tr>
                          );
                        })}
                      {Object.keys(productsAvailableCopy).map((item, index) => {
                        return (
                          <tr key={index}>
                            <th className="tbl-td-th">{productsAvailableCopy[item].label}</th>
                            <th className="tbl-td-th">
                              <ToggleButton
                                inactiveLabel={"Inactive"}
                                activeLabel={"Active"}
                                value={item.product_name}
                                onToggle={(value) => {
                                  companyProductAddandRemove(
                                    'Inactive',
                                    item
                                  );
                                }}
                              />
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </ProductModal>
      <Toaster />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { products } = state.entities;

  return { products };
};

export default connect(mapStateToProps)(CompanyDetailsModal);