import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import api from "../middleware/api";
import rootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";



const prod = process.env.NODE_ENV === "production";

const composeEnhancers =
  process.env.NODE_ENV === "production"
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : () => {};

let middleware = [thunk, api];
if (!prod) {
  const logger = createLogger({ collapsed: true, diff: true });
  middleware = [...middleware, logger];
}
console.log(composeEnhancers);

const configureStore = (preloadedState) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
