import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import { isEmpty } from "lodash";

const AssociationCompanyList = (props) => {
  const {
    match: { params },
    history,
  } = props;
  const [getAssociationById, loading, response] = useFetch();

  const fetchAssociations = () => {
    const payload = {
      endPoint: `/api/tlm/cp/association/companies/${params.id}`,
      method: "get",
    };

    getAssociationById(payload);
  };

  useEffect(() => {
    fetchAssociations();
  }, []);

  return (
    <div className="product-modal-content cus_report ">
      <span onClick={history.goBack} className="back-btn">
        ← Back to list of Associations
      </span>
      <div className="association-list my-association-list">
        <div className="">
          {loading ? (
            <Loading />
          ) : (
            <>
              <h1>{!isEmpty(response) && response.title}</h1>
              <div className="product-table table-responsive">
                <table className="table associationcompany-table">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Short name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(response) && Array.isArray(response.companies)
                      ? response.companies.map((item, index) => {
                          return (
                            <tr className="row" key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.short_name}</td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AssociationCompanyList);
