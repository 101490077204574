import React, { Component } from "react";
import { connect } from "react-redux";
import ConfigurationModel from "./ConfigurationModel";
import "../../styles/career.css";
import Loading from "../ui/Loading";
import { fetchJobs, markAsReviewed } from "../../actions/jobs";
import "../../styles/JobsBody.css";
import IntegrationSettings from "../IntegrationSettings";
import HideShow from "../ui/HideShow";
import { IndeedDocumentation } from "./JobBoard";
import { getQuickApplyApplication } from "../../actions/integrations";
import { isEmpty } from "lodash";
import Indeed from "../jobsites/Indeed";
import ZipRecruiter from "../jobsites/ZipRecruiter";
// import "../../styles/JobsBody.css";

class CareerIntegration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobStates: {},
      jobApplicants: [],
      jobApplicantsCopy: [],
      jobs: [],
      isLoader: false,
      jobsCollapse: true,
      expandedAppID: "",
      applicantsData: [],
    };
  }

  fetchApplication = async () => {
    this.setState({
      isLoader: true,
    });
    const { dispatch } = this.props;
    const { response } = await dispatch(fetchJobs("quickapl"));
    if (response && response.entities) {
      this.setState({
        applicantsData: Object.values(response.entities.jobs).map((item) => ({
          ...item,
          open: false,
          loading: false,
          currentPage: 0,
        })),
      });
    }

    this.setState({
      isLoader: false,
    });
  };

  componentDidMount() {
    this.fetchApplication();
  }

  convertTime(timeInMillis) {
    var d = new Date(0);
    d.setUTCSeconds(timeInMillis / 1000);
    return d.toDateString();
  }

  minAll() {
    const { applicantsData } = this.state;
    let copy = [...applicantsData];
    copy = copy.map((item) => ({ ...item, open: false }));
    this.setState({ applicantsData: copy });
  }

  fetchApplicantsData = async (jobID, index, record, toggle) => {
    const { dispatch } = this.props;
    const { applicantsData } = this.state;
    const copy = [...applicantsData];
    copy[index].loading = true;

    if (toggle) copy[index].open = !copy[index].open;

    this.setState({ applicantsData: copy });

    if (!isEmpty(copy[index].applications) && toggle) {
      copy[index].loading = false;
      this.setState({ applicantsData: copy });
      return;
    }

    if (copy[index].application_count === 0) {
      copy[index].applications = [];
      copy[index].loading = false;
      this.setState({ applicantsData: copy });
      return;
    }

    const { response } = await dispatch(
      getQuickApplyApplication(jobID, record)
    );

    if (response) {
      copy[index].applications = Object.values(response);
      copy[index].currentPage = record;
    }
    copy[index].loading = false;
    this.setState({ applicantsData: copy });
  };

  toggleJobExpansion(jobID, index) {
    this.fetchApplicantsData(jobID, index, 1, true);

    // let { jobStates } = this.state;

    // if (jobStates[jobID] === true) {
    //   this.setState({ jobStates: { ...jobStates, [jobID]: false } });
    // } else {
    //   this.setState({ jobStates: { ...jobStates, [jobID]: true } });
    // }
  }

  handleSelectFilter = (e) => {
    const { value } = e.target;
    const { jobApplicantsCopy } = this.state;
    if (value === "all") {
      this.setState({
        jobApplicants: jobApplicantsCopy,
      });
      return;
    }

    const filterData = jobApplicantsCopy.filter(
      (item) => item.op_job_id == value // eslint-disable-line
    );
    this.setState({ jobApplicants: filterData });
  };

  expandJobApp(newjobID) {
    const { expandedAppID } = this.state;

    if (newjobID === expandedAppID) {
      this.setState({ expandedAppID: 0 });
      return;
    }

    this.setState({ expandedAppID: newjobID });
  }

  markAsReviewed(appID) {
    const { dispatch } = this.props;

    dispatch(markAsReviewed(appID));
    this.fetchApplication();
  }

  render() {
    const { data, saveSettings, settingsAreSaving, error } = this.props;
    const { idUpdating } = this.props;
    const { expandedAppID, isLoader, applicantsData } = this.state;

    if (data === undefined) {
      return null;
    }

    return (
      <div className="career JobsBody JobBoard">
        <div className="head">
          <h1>
            <div>Quick apply integration</div>
            <div className="desc disappearSmall">
              This integration automatically pushes your job requisitions to
              Quick apply. From here, you can choose to sponsor your posting on
              a per-job basis. If you'd like to opt-out of this integration,
              select Disabled below under Integration Settings.
            </div>
          </h1>
          <div className="img">
            <img src={`/images/${data.image_name}`} alt=" " />
          </div>
        </div>
        <div>
          <ConfigurationModel jobs={applicantsData} />
        </div>
        <div className="applicant">
          <div className="header">
            <div className="left">
              <h2>Applicants</h2>
              <div>
                <button onClick={() => this.fetchApplication()}>REFRESH</button>
              </div>
            </div>
            <div>
              <button onClick={() => this.minAll()}>Minimize All</button>
            </div>
          </div>
          {isLoader ? (
            <Loading />
          ) : (
            <div>
              {applicantsData && applicantsData.length === 0 && (
                <div className="jobCont">
                  Your currently have no open job requisitions
                </div>
              )}
              {Array.isArray(applicantsData) &&
                applicantsData.map((job, index) => (
                  <div className="jobCont" key={job.id}>
                    <div
                      className="jobTitle"
                      onClick={() => this.toggleJobExpansion(job.id, index)}
                    >
                      <div>
                        {job.title} <span>({job.application_count})</span>
                      </div>
                      <div>
                        {!job.open ? <button>+</button> : <button>-</button>}
                      </div>
                    </div>
                    {job.loading ? (
                      <Loading />
                    ) : (
                      job.open && (
                        <div className="appsCont">
                          {Array.isArray(job.applications) &&
                          job.applications.length === 0 ? (
                            <div>No applications yet</div>
                          ) : (
                            <div>
                              {Array.isArray(job.applications) &&
                                job.applications.map((app) => (
                                  <div
                                    className={
                                      expandedAppID === app.id
                                        ? "expanded app"
                                        : "nonexpanded app"
                                    }
                                    key={app.id}
                                  >
                                    <div
                                      onClick={() => this.expandJobApp(app.id)}
                                      className={
                                        app.user_reviewed
                                          ? "top reviewed"
                                          : "top notReviewed"
                                      }
                                    >
                                      <div className="nameCont">
                                        <div className="name">
                                          {app.applicant_name}
                                        </div>
                                        <div
                                          className={
                                            app.finish_email_sent
                                              ? "sent email"
                                              : "notsent email"
                                          }
                                        >
                                          {app.finish_email_sent ? (
                                            <span>SENT APPLY EMAIL</span>
                                          ) : (
                                            <span>APPLY EMAIL NOT SENT</span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="rightSide">
                                        {app.user_reviewed && (
                                          <div className="reviewed">
                                            &#10003;
                                          </div>
                                        )}
                                        <div className="sourceTime">
                                          {app.source},{" "}
                                          {this.convertTime(
                                            app.applied_on_millis
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {expandedAppID === app.id &&
                                      app.source === "Indeed" && (
                                        <div className="bottom">
                                          <Indeed
                                            markAsReviewed={() =>
                                              this.markAsReviewed(app.id)
                                            }
                                            idUpdating={idUpdating}
                                            application={app}
                                            sendEmail={() =>
                                              this.sendFinishEmail(app.id)
                                            }
                                          />
                                        </div>
                                      )}
                                    {expandedAppID === app.id &&
                                      app.source === "ZipRecruiter" && (
                                        <div className="bottom">
                                          <ZipRecruiter
                                            markAsReviewed={() =>
                                              this.markAsReviewed(app.id)
                                            }
                                            idUpdating={idUpdating}
                                            application={app}
                                            sendEmail={() =>
                                              this.sendFinishEmail(app.id)
                                            }
                                          />
                                        </div>
                                      )}
                                  </div>
                                ))}
                            </div>
                          )}
                          {/* {job.application_count > 10 && (
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="Next"
                              forcePage={Number(job.currentPage) - 1}
                              onPageChange={(event) =>
                                this.handlePageClick(event, job.id, index)
                              }
                              pageCount={Math.ceil(job.application_count / 10)}
                              previousLabel="Previous"
                              containerClassName={"pagination-container"}
                              previousLinkClassName={"pagination__link"}
                              nextLinkClassName={"pagination__link"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={1}
                            />
                          )} */}
                        </div>
                      )
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="intSect">
          <div className="settings sect">
            <h2>Integration Settings</h2>
            <IntegrationSettings
              settingsAreSaving={settingsAreSaving}
              settings={data.settings}
              saveSettings={saveSettings}
              error={error}
              integrationName={data.integration_name}
              integrationId={data.integration_id}
            />
          </div>
        </div>
        <div>
          <HideShow
            label={<h2>Integration Documentation</h2>}
            body={IndeedDocumentation}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo } = state.products;
  const { isFetching, idUpdating } = state.jobs;

  // const jobsListJoin =
  //   list === undefined
  //     ? []
  //     : list.map((jobid) => {
  //         let thisJob = Object.assign({}, jobs[jobid]);

  //         const appsJoin = thisJob.CC_sponsorship.applications.map(
  //           (appID) => applications[appID]
  //         );
  //         thisJob.CC_sponsorship.applications = appsJoin;
  //         return thisJob;
  //       });

  return {
    isFetching,
    jobs: [],
    userInfo,
    idUpdating,
  };
};

export default connect(mapStateToProps)(CareerIntegration);
