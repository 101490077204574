import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Loading from './ui/Loading'; // Assuming you have a Loading component
import { getToken } from '../actions/auth'; // Assuming you have a function to get tokens
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import useToggle from '../dashboard/hooks/useToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';


const CloudPunchInstallationSettings = () => {
    const [usertype, setUsertype] = useState(0);
    const [password, handlePassowrd] = useToggle()
    const [cPassword, handleCPassowrd] = useToggle()

    //  const [installationSetupId, setinstallationSetupId] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [isLoading, setLoading] = useState(false);
    const segments = window.location.pathname.split('/');
    var installationId = segments[segments.length - 2];

    function password_validate(password) {
        let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
        if (password.match(check)) {
            return false
        } else {
            return true
        }

    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            // setLoading(true);
            if (newPassword !== confirmNewPassword) {
                toast.error("password and comfirm password not match");
                setLoading(false);
                setNewPassword("");
                setConfirmNewPassword("");
            } else {
                if (newPassword === "") {
                    toast.error("password can't be blank");
                    setLoading(false);
                    setNewPassword("");
                    setConfirmNewPassword("");
                } else {

                    if (password_validate(newPassword)) {
                        toast.error("password not stronge")
                        return
                    }

                    if (usertype === "") {
                        toast.error("Please select user type")
                        return
                    }

                    const { userToken, token } = getToken();
                    const requestBody = {
                        "installation_id": installationId,
                        "password": newPassword,
                        "user_type": usertype
                    };

                    await axios.post('/api/tlm/cp/installations/password/update', requestBody, {
                        headers: {
                            Opusertoken: userToken,
                            Authorization: token,
                        },
                    });

                    setLoading(false);
                    toast.success("Password updated successfully, please wait 5 minute for update in installation!");
                    setNewPassword("");
                    setConfirmNewPassword("");
                }
            }

        } catch (error) {
            toast.error(error)
            setLoading(false);
            console.error('Error updating password:', error);
        }
    };

    const handlePassClear = () => {
        setNewPassword("");
        setConfirmNewPassword("");
    };

    return (
        <div>
            <Toaster />
            <div className='modalContainer'>
                <div className='sidebarCustom'>
                    <ul className='navlistCustom'>
                        <li>
                            <NavLink to={'/'} className={`navitemCustom ${segments[segments.length - 1] === "settings" ? "myactive" : ""}`}>Security</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/app/system-product/cloudpunch/installation/' + installationId + '/settings/logs'} className={`navitemCustom ${segments[segments.length - 1] === "logs" ? "myactive" : ""}`}>Logs</NavLink>
                        </li>
                    </ul>
                </div>
                <div className='mainContentCustom'>
                    <fieldset className='customFieldSet'>
                        <legend>Password</legend>
                        {isLoading ? <Loading /> :

                            <form onSubmit={handleSubmit}>
                                <div className='formFieldCustom'>
                                    <label htmlFor="currentPassword">User Type</label>
                                    <div className='customFlex'>
                                        <div>
                                            <input type='radio' name='usertype' id='ChangepasswordAdmin' onClick={() => setUsertype(1)} required />
                                            <label htmlFor='ChangepasswordAdmin'>Admin</label>
                                        </div>
                                        <div>
                                            <input type='radio' name='usertype' id='ChangepasswordManager' onClick={() => setUsertype(2)} required />
                                            <label htmlFor='ChangepasswordManager'>Manager</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='formFieldCustom'>
                                <label htmlFor="currentPassword">Current Password</label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    required
                                />
                            </div> */}
                                <div className='formFieldCustom'>
                                    <label htmlFor="newPassword">New Password</label>
                                    <div className='relative'>
                                        <input
                                            type={password ? "text" : "password"}
                                            id="newPassword"
                                            value={newPassword}
                                            className='full-width'
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                        <FontAwesomeIcon
                                            onClick={handlePassowrd}
                                            style={{ position: "absolute", top: "50%", right: "10px", transform: "translate(0px, -50%)", cursor: "pointer" }}
                                            icon={password ? solid("eye-slash") : solid("eye")}
                                        />
                                    </div>
                                </div>
                                <div className='formFieldCustom'>
                                    <label htmlFor="confirmNewPassword">Confirm New Password</label>
                                    <div className='relative'>
                                        <input
                                            type={cPassword ? "text" : "password"}
                                            id="confirmNewPassword"
                                            value={confirmNewPassword}
                                            className='full-width'
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            required
                                        />
                                        <FontAwesomeIcon
                                            onClick={handleCPassowrd}
                                            style={{ position: "absolute", top: "50%", right: "10px", transform: "translate(0px, -50%)", cursor: "pointer" }}
                                            icon={cPassword ? solid("eye-slash") : solid("eye")}
                                        />
                                    </div>

                                </div>
                                <div className='flexCenterCustom'>
                                    <button type="submit" className='passwordChangeBtn'>Change Password</button>
                                    <button type="submit" onClick={handlePassClear} className='passwordClearBtn'>Clear</button>
                                </div>
                            </form>
                        }
                    </fieldset>
                </div>
            </div>

        </div>
    );
};

export default CloudPunchInstallationSettings;
