import * as type from "../actions/colortheme";
const initialState = {
    themetoggle: false,
    themeloading: false,
    thememodal: false,
    themewidth: '',
    dynamicloadertype: 'heart',
    colorthemeData: [],
    fontsizestyle: 'italic',
    fontsizefamily: '',
    dynamicloadercolor: '',
    themecustomize: [],
    logoutmodal: false
};

const colortheme = (state = initialState, action) => {    
    switch (action.type) {
        case type.GET_DIMENTION_WIDTH:
            return { ...state, themetoggle: !state.themetoggle };
        case type.GET_DIMENTION_MODAL:
            return { ...state, thememodal: !state.thememodal };
        case type.WIDTH_VALUE:
            return { ...state, themewidth: action.payload };
        case type.LOADER_DYNAMIC_TYPE:
            return { ...state, dynamicloadertype: action.payload };
        case type.FONT_SIZE:
            return { ...state, fontsizestyle: action.payload };
        case type.FONT_FAMILY:
            return { ...state, fontsizefamily: action.payload };
        case type.LOADER_DYNAMIC_COLOR:
            return { ...state, dynamicloadercolor: action.payload };
        case type.THEME_CUSTOMIZATION:
            return { ...state, themecustomize: action.payload };
        case type.LOGOUT_MODAL:
            return { ...state, logoutmodal: !state.logoutmodal };

        case type.GET_COLOR_THEME_FAILURE:
            return { ...state, themeloading: false };
        case type.GET_COLOR_THEME_REQUEST:
            return { ...state, themeloading: true };
        case type.GET_COLOR_THEME_SUCCESS:
            // let data = [];
            // if (action.response) {

            //     data = Object.values(action.response);
            // }
            return { ...state, themecustomize: action.response, themeloading: false };


        default:
            return { themeloading: false };
    }
};

export default colortheme;