import React, { useEffect, useState } from 'react'
import ProductModal from '../UI/ProductModal'
import ReactPaginate from 'react-paginate'
import Loading from '../../../components/ui/Loading'
import useFetch from '../../hooks/useFetch'

export default function EmployeeCountModal(props) {
    const { setEmpCount, tokenId } = props
    const [employeesdata, setEmployeesdata] = useState([])
    const [rowPerPage] = useState(15);
    const [currentPage, setCurrentPage] = useState(0);
    const [fetchCompany, companyLoading] = useFetch();

    const close = () => {
        setEmpCount(false)
    }
    const handleFetchCompany = async () => {
        try {
            const payload = {
                endPoint: `/api/company/employees/${tokenId}`,
                method: "get",
            };
            const res = await fetchCompany(payload);
            setEmployeesdata(res);
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        handleFetchCompany()
    }, [])

    const offset = currentPage * rowPerPage;
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }
    const pageCount = Math.ceil(
        Array.isArray(employeesdata)
            ? employeesdata.length / rowPerPage
            : 0 / rowPerPage
    );
    return (
        <div>
            <ProductModal
                heading="Employees Count"
                closeModal={close}
            >
                {companyLoading ?
                    <Loading /> : (
                        <div className="product-company-detail">
                            <div className="company_system_table">
                                <label htmlFor="" style={{ marginLeft: '50px', fontSize: '23px', fontWeight: '600' }}>
                                    Employees ({employeesdata.length})</label>
                                {employeesdata.length === 0 ?
                                    <div style={{textAlign:'center',fontSize:'25px',fontWeight:'bold'}} >
                                        No Data Found...
                                    </div> :
                                    <div className="tbl_container tabmars">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="tbl-td-th">Account Id</th>
                                                    <th className="tbl-td-th">First Name</th>
                                                    <th className="tbl-td-th">Last Name</th>
                                                    <th className="tbl-td-th">Primary Email</th>
                                                    <th className="tbl-td-th">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(employeesdata) &&
                                                    employeesdata
                                                        .slice(offset, offset + rowPerPage)
                                                        .map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tbl-td-th">{item.Account_id}</td>
                                                                    <td className="tbl-td-th">{item.first_name}</td>
                                                                    <td className="tbl-td-th">{item.last_name}</td>
                                                                    <td className="tbl-td-th">{item.primary_email}</td>
                                                                    <td className="tbl-td-th">{item.status}</td>
                                                                </tr>
                                                            );
                                                        })}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            onPageChange={handlePageClick}
                                            pageCount={pageCount}
                                            previousLabel="Previous"
                                            renderOnZeroPageCount={null}
                                            containerClassName={"pagination-container"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"disabled"}
                                            activeClassName={"active"}
                                            pageRangeDisplayed={2}
                                            marginPagesDisplayed={1}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    )}
            </ProductModal>
        </div>
    )
}
