import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import useToggle from "../../hooks/useToggle";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import Swal from "sweetalert2";
import { connect } from "react-redux";

const productOpenAsModal = ["user-provisioning", "imports", "multiple"];
const ProductCard = ({
  product_url,
  product_name,
  product_description,
  match,
  history,
  userAdminPermission,
  image_url,
  products,
  index,
  onMouseDown,
  Dimention,
  size
}) => {

  const [editing, handleEditing] = useToggle(false);
  const [formValue, setFormValue] = useState({
    product_name,
    product_description,
    imageFile: "",
    image_name: image_url,
    image_nameOld: image_url,
  });
  const [saveCard, loadingCard] = useFetch();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValue({ ...formValue, [name]: value });
  // };
  const handleGoIntoProduct = () => {
    if (!editing) {
      const path = productOpenAsModal.includes(product_url)
        ? "product"
        : "system-product";
      // history.push(`${match.url}/${path}/${product_url}`, {data: "dattacoming"});
      history.push({
        pathname: `${match.url}/${path}/${product_url}`,
        state: {
          "product_name": product_name,
          "product_description": product_description,
          "image_name": image_url
        }
      });
    }
  };

  //redux start

  //redux end

  const handleEditAble = (event) => {
    event.stopPropagation();
    handleEditing();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const selectableMaxFileSize = 1024 * 1024 * 0.5; // 500KB
    const imageIsPNG = file.type.includes("png");

    if (selectableMaxFileSize > file.size && imageIsPNG) {
      let url = URL.createObjectURL(file);
      setFormValue({
        ...formValue,
        imageFile: file,
        image_name: url,
      });
    } else if (!imageIsPNG) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can upload only png image!",
        confirmButtonColor: "#000",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Image size should be less than 500KB!",
      });
    }
  };

  const handleSave = async (event) => {
    event.stopPropagation();
    var formData = new FormData();
    formData.append("content_type", product_url);
    formData.append("header", formValue.product_name);
    formData.append("contents", formValue.product_description);
    formData.append("logo", formValue.imageFile);

    const payload = {
      endPoint: "/api/comp/customize/content",
      method: "POST",
      body: formData,
      formData: true,
    };
    window.location.reload()
    await saveCard(payload);
    handleEditing();
  };
  //resize height and width
  localStorage.setItem('userAdminPermission', userAdminPermission);
  //
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '......' : text;
  };
  return (
    <div className={`prod-card ${userAdminPermission ? "pt-4" : ""}`}
      key={product_url}
      onClick={handleGoIntoProduct}
    >
      {userAdminPermission && (
        <div className="edit-btn-container">
          {editing ? (
            <>
              <button
                title="Save"
                className="ms-1 mt-1 me-1"
                onClick={handleSave}
                disabled={!formValue.product_description || !formValue.product_name}
              >
                Save
              </button>
              <button title="Upload Image" className="mt-1 me-1">
                <label className="cursor-pointer" htmlFor="camera-22">
                  <FontAwesomeIcon icon={solid("camera")} />
                </label>
                <input
                  type="file"
                  id="camera-22"
                  hidden
                  onChange={handleImageChange}
                />
              </button>
            </>
          ) : (
            <span
              title="Edit"
              className="edit-icon-btn"
              onClick={handleEditAble}
            >
              <FontAwesomeIcon icon={solid("pen-to-square")} />
            </span>
          )}
        </div>
      )}
      {loadingCard ? (
        <Loading />
      ) : (
        <>
          <div className="card-bar-one product-cards-outer"
            style={{ height: size.y, overflowY: 'auto' }}
          >
            <div className="card-content product-cards">
              <div className="relative">
                <input
                  type="text"
                  className={`prod-heading ${editing ? "active z-index-99" : ""
                    }`}
                  value={formValue.product_name}
                  name="product_name"
                  // onChange={handleChange}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.replace(/^\s+/, '');
                    setFormValue({ ...formValue, product_name: trimmedValue });
                  }}
                  disabled={!editing}
                />
                <div
                  className="prod-description-extra-dev"
                  onClick={handleGoIntoProduct}
                ></div>
              </div>
              {/* <p className="prod-heading">{product_name}</p> */}
              <div className="relative">
                <textarea
                  className={`prod-description ${editing ? "active z-index-99" : ""
                    }`}
                  name="product_description"
                  value={editing ? formValue.product_description : truncateText(formValue.product_description, 10)}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.replace(/^\s+/, '');
                    setFormValue({ ...formValue, product_description: trimmedValue });
                  }}
                  disabled={!editing}
                ></textarea>
                <div
                  className="prod-description-extra-dev"
                  onClick={handleGoIntoProduct}
                ></div>
              </div>

              {/* <p>{product_description}</p> */}
            </div>
            <div className="prod-img">
              <img src={formValue.image_name} alt="" />
            </div>
          </div>
          {index === products.length - 1 && Dimention === true ? <button id="draghandle" type="button" onMouseDown={onMouseDown}>
            Resize
          </button> : ''}
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {

  const headingfont = state.colortheme.themecustomize && state.colortheme.themecustomize.theme_font_style.product_card_font_style.font_style_heading.font_style;
  return { ThemeFontstyle: state.colortheme, FontHeading: headingfont };
};

export default withRouter(connect(mapStateToProps)(ProductCard));
// export default withRouter(ProductCard);
