import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { getToken } from "../../../actions/auth";
import {
    createMineralAccessControl,
    editAccessControl,
    editMineralAccessControl,
} from "../../../actions/products";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import HideShow from "../../../components/ui/HideShow";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const AccessControlIntegration = (props) => {
    const { dispatch, companyProductId, accessControls, userInfo, currentIntegration } = props;
    const [showAccess, handleShowAccess] = useToggle(false);
    const [employees, setEmployees] = useState([]);
    const [accessControlsState, setAccessControlsState] = useState(accessControls);
    const [setIsFetching] = useState(false);
    const [selectEmail, setSelectEmail] = useState("");
    const { token, userToken } = getToken();
    const [accessType, setAccessType] = useState('');
    const [accessRemove] = useFetch();
    const [style, setStyle] = useState("light");

    if (userInfo && !userInfo.is_system_admin) {
        return null;
    }

    const fetchEmployees = async () => {
        try {
            setIsFetching(true);
            const response = await axios.get(`/api/mineral/users`, {
                headers: {
                    Opusertoken: userToken,
                    Authorization: token,
                },
            });
            const data = response.data;
            if (Array.isArray(data.users)) {
                setEmployees(data.users);
            }
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            console.log("error", error);
        }
    };
    const handleSelectChange = (e) => {
        const selectedrole = employees.find((emp) => emp.fullName === e.target.value);
        setAccessType(selectedrole.role)
        const { value } = e.target;
        setSelectEmail(value);
    };
    const handleChange = (e) => {
        setAccessType(e.target.value);
    };

    const handleSubmit = () => {
        if (!selectEmail) {
            return;
        }
        const selectedEmployee = employees.find((emp) => emp.fullName === selectEmail);

        const newAC = {
            company_product_id: companyProductId,
            employee_name: selectedEmployee.fullName,
            userId: selectedEmployee.userId,
            role: accessType,
            integration_id: currentIntegration.integration_id,
            company_id: userInfo.company_id,
        };

        dispatch(createMineralAccessControl(newAC));
    };

    const handleChangeReadWrite = (ac, newAccessType) => () => {
        console.log(ac, newAccessType);
        if (ac.access_type === newAccessType) {
            return;
        }
        ac.access_type = newAccessType;
        dispatch(editAccessControl(ac.id, ac));
    };

    const handleReActiveRemove = (ac, newActive) => () => {
        console.log(ac, newActive, 'data1');
        if (ac.active === newActive) {
            return;
        }
        ac.active = newActive;
        dispatch(editMineralAccessControl(ac.id, ac));
    };

    const handleAccessDelete = (ac, index) => async () => {
        setIsFetching(true);
        let formData = new FormData();
        formData.append("id", ac.id);

        const payload = {
            endPoint: "/api/companyproductaccess/remove",
            body: formData,
            method: "POST",
            formData: true,
        };
        await accessRemove(payload);

        const copy = [...accessControlsState];
        copy.splice(index, 1);
        setAccessControlsState(copy);

        setIsFetching(false);
    };

    useEffect(() => {
        if (employees.length === 0 && showAccess) {
            fetchEmployees();
        }
    }, [showAccess]);

    // if (isFetching) {
    //     return <Loading />;
    // }

    const changeStyle = () => {
        if (style !== "light") setStyle("light");
        else setStyle("dark");
    };

    return (
        // className="product-setting-inner1" 
        <div className="product-setting-inner email-notification mb-2">
            <HideShow
                label={<label>Access Control</label>}
                body={<div>
                    <p>
                        Control which employees in your company can access this Product. Only
                        Company Administrators can access this area.
                    </p>
                    <div className="scrollable-content">
                        {Array.isArray(accessControlsState) &&
                            accessControlsState.map((item, index) => {
                                return (
                                    <div
                                        className={`access-control mb-2 ${!item.active && "inactive"}`}
                                        key={item.employee_name}
                                    >
                                        <div className="my-flex my-justify-content-between my-align-item-center">
                                            <h3>{item.employee_name}</h3>
                                            <div>
                                                <button
                                                    className={`me-10-px ${item.access_type === "R" ? "" : "inactive"
                                                        }`}
                                                    onClick={handleChangeReadWrite(item, "R")}
                                                >
                                                    READ
                                                </button>
                                                <button
                                                    className={`${item.access_type === "W" ? "" : "inactive"}`}
                                                    onClick={handleChangeReadWrite(item, "W")}
                                                >
                                                    WRITE
                                                </button>
                                            </div>
                                        </div>
                                        {item.active ? (
                                            <button onClick={handleReActiveRemove(item, false)}>
                                                Remove Access
                                            </button>
                                        ) : (
                                            <button onClick={handleReActiveRemove(item, true)}>
                                                REACTIVATE
                                            </button>
                                        )}
                                        <button
                                            className="ms-1"
                                            onClick={handleAccessDelete(item, index)}
                                        >
                                            Remove user
                                        </button>
                                    </div>
                                );
                            })}
                    </div>
                    {showAccess && (
                        <div className="my-flex">
                            <div className="employees select-employee mt-2 one-col-12">
                                <div className="top">
                                    <div>Select Employee</div>
                                    <select
                                        className="mb-2"
                                        value={selectEmail}
                                        onChange={handleSelectChange}
                                    >
                                        <option disabled value="">
                                            Select Employee
                                        </option>
                                        {Array.isArray(employees) &&
                                            employees.map((item, key) => (
                                                <option key={key} value={item.fullName}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className="Access-type one-col-12">
                                <div>Access Type</div>
                                <select
                                    className="mt-4"
                                    value={accessType}
                                    onChange={handleChange}
                                    disabled={style === 'light' ? true : false}
                                >
                                    <option disabled value="">Select Access type</option>
                                    <option value='Company Admin' >Company Admin</option>
                                    <option value='Employee' >Employee</option>
                                </select>
                                <FontAwesomeIcon onClick={changeStyle} icon={solid("arrows-rotate")} className={style}/>
                            </div>
                            <div className="bot mt-2 one-col-6">    
                                <button onClick={handleSubmit} className="m-0">
                                    SAVE
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="product-setting-inner2">
                        <button onClick={handleShowAccess}>
                            {showAccess ? "Close" : "CHANGE USER ACCESS"}
                        </button>
                    </div>
                </div>}
            />
        </div>
    );
};

const mapStateToProps = (state, newProps) => {
    const { products, integrations } = state.entities;
    const {
        isFetching: productsAreFetching,
        isUpdating: productsAreUpdating,
        userInfo,
    } = state.products;
    const { match } = newProps;
    const prodURL = match.url.split("/");


    const productData = products["user-provisioning"];
    const intsList =
        productData === undefined
            ? []
            : productData.integrations.map((intURL) => {
                return integrations[intURL];
            });
    const currentIntegration = intsList.find((item) => item.url === prodURL[prodURL.length - 1]) || {}

    return {
        integrations: intsList,
        currentIntegration,
        productsAreFetching,
        productsAreUpdating,
        settings: productData ? productData.settings : {},
        companyProductId:
            productData === undefined ? null : productData.company_product_id,
        accessControls: currentIntegration !== undefined && currentIntegration.access_controls,
        userInfo,
    };
};

export default withRouter(connect(mapStateToProps)(AccessControlIntegration));
